@font-face { font-family: u8fe59; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAO8AAsAAAAABdAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqRzbGY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAACgAAAAoTOokF2hlYWQAAAH4AAAALgAAADYnek3JaGhlYQAAAigAAAAbAAAAJADxAEBobXR4AAACRAAAAAgAAAAIAGQAAGxvY2EAAAJMAAAABgAAAAYAFAAAbWF4cAAAAlQAAAAeAAAAIAENABVuYW1lAAACdAAAASkAAAIWm5e+CnBvc3QAAAOgAAAAGwAAACxqOAEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGQwYpzAwMrAwGDP4AYk+aC0AQMLgyQDAxMDKzMDVhCQ5prC4PCA4QEDQwqQywkmGRgYQQQA+uoGagAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAAAAQAAAAAAiwBiAAgAADcHFyMVMwcXN2kKEG9vEAoiYgoRDhAKIQAAeJxjYGRgYABinp8XLsfz23xl4GZIAYowPH7RwYxMM3QzJAFJDgYmEAcARrkKXAAAeJxjYGRgYEhhYICQ/28ydDMwMqACJgBIQgMwAAAAAAAAZAAAAAAAAAAUAAB4nGNgZGBgYGLgZADRIBYDAxcQMjD8B/MZAAl4ASMAAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BigABGBuyAiZGJkZmBKdOAgQEAAzIAqAA=) format('woff');font-weight: normal;font-style: normal;}
  .mainRooms {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}
  @media(max-width: 570px) {.mainRooms {
    margin-top: 0px
}
  }
  .mainRooms__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 45px;
    width: 100%;
  }
  @media(max-width: 996px) {
  .mainRooms__grid {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
  }
    }
  @media(max-width: 570px) {
  .mainRooms__grid {
      margin-top: 25px
  }
    }
  .mainRooms__link {
    color: #D8AB5E;
  }
  .mainRooms__button {
    height: 200px;
    width: 200px;
    border: 2px solid #D8AB5E;
    position: absolute;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 2;
    border-radius: 50%;
    color: #ffffff;
    font-weight: 900;
    font-size: 22px;
    font-family: 'Noah';
    -webkit-transition: .2s cubic-bezier(0.4, 0, 1, 1);
    transition: .2s cubic-bezier(0.4, 0, 1, 1);   
  }
  .mainRooms__button:after {
      position: relative;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: u8fe59;
      font-weight: normal;
      content: '\E000';
      font-size: 18px;
      color: #D8AB5E;
      -webkit-transition: .2s cubic-bezier(0.4, 0, 1, 1);
      transition: .2s cubic-bezier(0.4, 0, 1, 1);
      left: 13px;
      top: 3px;
      z-index: 2;
    }
  .mainRooms__left {
    width: 75%;
  }
  @media(max-width: 1279px) {
  .mainRooms__left {
      width: 68%;
      right: 10px;
      position: relative
  }
    }
  @media(max-width: 996px) {
  .mainRooms__left {
      position: initial;
      width: 100%
  }
    }
  .mainRooms__right {
    width: 25%;
  }
  @media(max-width: 1279px) {
  .mainRooms__right {
      width: 32%
  }
    }
  @media(max-width: 996px) {
  .mainRooms__right {
      width: 100%;
      top: -140px;
      position: relative
  }
    }
  @media(max-width: 570px) {
  .mainRooms__right {
      top: -35px
  }
    }
  .mainRooms__description {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    max-width: 270px;
    margin: 40px 0 55px 45px;
  }
  @media(max-width: 1279px) {
  .mainRooms__description {
      margin: 40px 0 20px 10px
  }
    }
  @media(max-width: 996px) {
  .mainRooms__description {
      margin: 40px 0 30px 10px
  }
    }
  .mainRooms__imageBig {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .mainRooms__imageShort {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 1;
    position: relative;
  }
  @media(max-width: 570px) {
  .mainRooms__imageShort {
      border-radius: 0
  }
    }
  .mainRooms__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    position: relative;
    top: -72px;
  }
  @media(max-width: 570px) {
  .mainRooms__footer {
      top: -35px
  }
    }
  .mainRooms__title {
    font-family: 'Noah';
    color: #ffffff;
    font-size: 24px;
    padding: 0 55px;
    margin-right: 45px;
    height: 30px;
    line-height: 24px;
  }
  .mainRooms__title--absolute {
      position: absolute;
      padding: 34px 40px;
      height: 24px;
      bottom: 0;
      z-index: 2;
    }
  .mainRooms__title--mobile {
      display: none;
    }
  @media(min-width: 571px) {
  .mainRooms__title--mobile {
        display: block
    }
      }
  @media(min-width: 769px) {
  .mainRooms__title--mobile {
        margin-right: 30px;
        padding-right: 20px
    }
      }
  .mainRooms__bg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .mainRooms__coverShort {
    position: relative;
    width: 450px;
    height: 280px;
    right: 95px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: .2s cubic-bezier(0.4, 0, 1, 1);
    transition: .2s cubic-bezier(0.4, 0, 1, 1);
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mainRooms__coverShort {
      display: block
  }
    }
  .mainRooms__coverShort:hover:after {
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 41, 41, 0.66)), to(rgba(41, 41, 41, 0.8)));
        background: linear-gradient(180deg, rgba(41, 41, 41, 0.66) 0%, rgba(41, 41, 41, 0.8) 100%);
      }
  .mainRooms__coverShort:hover .mainRooms__button {
        background: #D8AB5E;
      }
  .mainRooms__coverShort:hover .mainRooms__button:after {
          color: #ffffff;
        }
  .mainRooms__coverShort:after {
      content: '';
      position: absolute;
      border-radius: 8px;
      display: block;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 41, 41, 0.25)), to(rgba(41, 41, 41, 0.55)));
      background: linear-gradient(180deg, rgba(41, 41, 41, 0.25) 0%, rgba(41, 41, 41, 0.55) 100%);
    }
  @media(max-width: 570px) {
  .mainRooms__coverShort:after {
        border-radius: 0px
    }
      }
  @media(max-width: 996px) {
  .mainRooms__coverShort {
      right: 0;
      width: 100%;
      height: 350px
  }
    }
  @media(max-width: 570px) {
  .mainRooms__coverShort {
      width: calc(100% + 30px);
      height: 280px;
      left: -15px;
      overflow: initial
  }
    }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .mainRooms__coverShort:after {
        content: '';
        position: absolute;
        border-radius: 8px;
        display: block;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(41, 41, 41, 0.25)), to(rgba(41, 41, 41, 0.55)));
        background: linear-gradient(180deg, rgba(41, 41, 41, 0.25) 0%, rgba(41, 41, 41, 0.55) 100%);
      }
        @media(max-width: 570px) {
      .mainRooms__coverShort:after {
          border-radius: 0px
      }
        }
    }
  .mainRooms__coverBig {
    border-radius: 8px;
    position: relative;
    height: 560px;
    overflow: hidden;
  }
  .mainRooms__coverBig:after {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      width: 100%;
      height: 100%;
      left: 0;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(68.25%, rgba(41, 41, 41, 0)), color-stop(91.54%, rgba(41, 41, 41, 0.8)));
      background: linear-gradient(180deg, rgba(41, 41, 41, 0) 68.25%, rgba(41, 41, 41, 0.8) 91.54%);
    }
  @media(max-width: 996px) {
  .mainRooms__coverBig {
      height: 350px
  }
    }
  @media(max-width: 570px) {
  .mainRooms__coverBig {
      width: calc(100% + 30px);
      height: 280px;
      left: -15px;
      overflow: initial
  }
    }
  .mainRooms:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 260px;
    left: 0;
    bottom: 0;
    background: #F5F5F7;
    z-index: -1;
  }