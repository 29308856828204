/* Layout */
@font-face {
  font-family: 'Noah';
  src: url('/fonts/Noah-Bold.ttf') format('truetype');

  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'Noah';
  src: url('/fonts/Noah-BoldItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 900;
}
@font-face {
  font-family: 'Noah';
  src: url('/fonts/Noah-Regular.ttf') format('truetype');

  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Noah';
  src: url('/fonts/Noah-RegularItalic.ttf') format('truetype');

  font-style: italic;
  font-weight: 700;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1.15;
}
body {
  min-width: 280px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #272729;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #ffffff;
}
::-moz-selection {
  background: #d8ab5e87;
  color: #272729;
}
::selection {
  background: #d8ab5e87;
  color: #272729;
}
code {
  color: #272729;
  font-family: Roboto;
  line-height: 23px;
  font-weight: 300;
  font-size: 14px;
  white-space: normal;
}
kbd {
  color: #272729;
  font-family: Roboto;
  line-height: 23px;
  font-weight: 300;
  font-size: 14px;
  white-space: normal;
}
samp {
  color: #272729;
  font-family: Roboto;
  line-height: 23px;
  font-weight: 300;
  font-size: 14px;
  white-space: normal;
}
pre {
  color: #272729;
  font-family: Roboto;
  line-height: 23px;
  font-weight: 300;
  font-size: 14px;
  white-space: normal;
}
ul {
  -webkit-padding-start: 24px;
          padding-inline-start: 24px;
}
ol li {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
}
ul li {
  list-style-type: none;
  line-height: 18px;
  font-size: 14px;
  color: #272729;
  font-family: 'Roboto';
  position: relative;
  margin-bottom: 12px;
}
ul li:before {
    content: '';
    width: 4px;
    height: 4px;
    border: 2px solid #D8AB5E;
    border-radius: 50px;
    position: absolute;
    left: -24px;
    top: 5px;
  }
p {
  line-height: 25px;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}
h1 {
  margin: 0;
}
