.mainFood__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }
    @media(max-width: 996px) {.mainFood__grid {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start
  }
    }
  .mainFood__right {
    width: 44%;
    margin-left: 6%;
  }
  @media(max-width: 996px) {
  .mainFood__right {
      width: 100%;
      margin-left: 0
  }
    }
  .mainFood__cover {
    height: 580px;
  }
  @media(max-width: 996px) {
  .mainFood__cover {
      width: 70%;
      height: 450px
  }
    }
  @media(max-width: 769px) {
  .mainFood__cover {
      width: 100%
  }
    }
  @media(max-width: 570px) {
  .mainFood__cover {
      height: 280px
  }
    }
  .mainFood__title--mobile {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 20px;
    }
  @media(min-width: 997px) {
  .mainFood__title--mobile {
        display: none
    }
      }
  .mainFood__title--desktop {
      display: none;
    }
  @media(min-width: 997px) {
  .mainFood__title--desktop {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
      }
  .mainFood__img {
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    height: 100%;
  }
  @media(min-width: 1440px) {
  .mainFood__img {
      height: 100%
  }
    }
  .mainFood__description {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    font-family: 'Roboto';
    margin: 24px 0;
    max-width: 440px;
  }
  .mainFood__ul {
    margin-bottom: 30px;
  }
  .mainFood__ul li {
        color: #272729;
        font-size: 16px;
        line-height: 28px;
    }
  .mainFood__ul li:before {
          top: 9px;
        }