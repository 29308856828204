.about__description {
  width: 100%;
}
  .about__description h3:first-child {
    margin-top: 0;
  }
  .about__description > p {
    margin: 0;
    font-size: 18px;
  }
  @media(max-width: 1279px) {
  .about__description > p {
      margin-top: 20px
  }
    }