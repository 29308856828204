.voucherSiblings__list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
    grid-gap: 1em;
  }
    @media(max-width: 570px) {.voucherSiblings__list {
      grid-template-columns: 1fr
  }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.voucherSiblings__list {
      display: block;
      width: 100%
  }
      .voucherSiblings__list > a {
        width: calc(24.975% - 22.5px);
        margin-bottom: 30px;
      }
      .voucherSiblings__list > a:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .voucherSiblings__list > a:last-child {
        margin-right: 0;
      }
      .voucherSiblings__list > a:nth-child(4n) {
        margin-right: 0;
        float: right;
      }
      .voucherSiblings__list > a:nth-child(4n + 1) {
        clear: both;
      }
    }