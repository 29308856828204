.roomConditions {
  width: 100%;
}
  .roomConditions__list {
    display: grid;
    width: 100%;
    grid-column-gap: 3%;
    grid-template-columns: 15% 22% 18% 15% 17%;
  }
  @media(max-width: 1279px) {
  .roomConditions__list {
      grid-template-columns: repeat(auto-fill, minmax(25%, 1fr))
  }
    }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .roomConditions__list {
      display: block;
      width: 100%
  }
      .roomConditions__list > dl {
        width: calc(16.65% - 25px);
        margin-bottom: 30px;
      }
      .roomConditions__list > dl:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .roomConditions__list > dl:last-child {
        margin-right: 0;
      }
      .roomConditions__list > dl:nth-child(6n) {
        margin-right: 0;
        float: right;
      }
      .roomConditions__list > dl:nth-child(6n + 1) {
        clear: both;
      }
    }
  .roomConditions__item {
    font-weight: 400;
  }
