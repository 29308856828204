.roomCharacteristic__list .definitionsList__dl {
      grid-template-columns: 120px 1fr;
    }
      @media(min-width: 571px){.roomCharacteristic__list .definitionsList__dl {
        grid-template-columns: 150px 1fr
    }     
      }
      @media(min-width: 769px){.roomCharacteristic__list .definitionsList__dl {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr))
    }           
      }
      @media(min-width: 997px){.roomCharacteristic__list .definitionsList__dl {
        grid-template-columns: 1fr
    }          
      }