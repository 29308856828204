@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.blog__list {
      display: block;
      width: 100%
  }
      .blog__list > a {
        width: calc(33.3% - 20px);
        margin-bottom: 30px;
      }
      .blog__list > a:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .blog__list > a:last-child {
        margin-right: 0;
      }
      .blog__list > a:nth-child(3n) {
        margin-right: 0;
        float: right;
      }
      .blog__list > a:nth-child(3n + 1) {
        clear: both;
      }
    }