@font-face { font-family: E8656d; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAP4AAsAAAAABigAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPAAAAFYpoTZMY21hcAAAAYAAAABKAAABcOEoo6pnbHlmAAABzAAAAGkAAACASDRnC2hlYWQAAAI4AAAAMAAAADYnIU1taGhlYQAAAmgAAAAZAAAAJABkAD9obXR4AAAChAAAAAgAAAAIADwAAGxvY2EAAAKMAAAABgAAAAYAQAAAbWF4cAAAApQAAAAeAAAAIAEOADxuYW1lAAACtAAAASkAAAIWm5e+CnBvc3QAAAPgAAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGSQY5zAwMrAwKDGoAwk2aG0BAMTAw8DkGBlZsAKAtJcUxgcHjA8YGAwAnJZgCRIJSNIDgDDHgVKeJztkLENgDAQA8/KQ4EYgSo1s1Cxv9gk+RjYIpbOkk9fPbAAJTmTAN2IkSut7AubfVB9E8M/tPZ37nCjlZndfXzLv3pBHRomCVEAAHicPY3BDkAwEER3S6tawgG9V1IHB6rVi///LGGRyCQzb+YywACuEzxpggXArMI6v4/WxXTgnzGFNHRixj5sb+4EKbrCMIYZPvY5YlNrlaunqFzKUhNJZMhpMMRlKznVseeVoO8b1CwK6AAAAHicY2BkYGAAYslSz+54fpuvDNwMRkARhscvOjgQ9P+/DIZAyMDAwcAEEgUAILUKX3icY2BkYGAwYgABGzBpyMDIgAqYAA/nAKQAAAAAAAAAADwAAAAAAAAAQAAAeJxjYGRgYGBiMABiBjCLgYELCBkY/oP5DAANXgFLAAB4nGWQPW7CQBSEx2BIAlKCFCkps1UKIpmfkgNAT0GXwpi1MbK91npBossJcoQcIaeIcoIcKGPzaGAtP38zb97uygAG+IWHenm4bWq9WrihOnGb9CDsk5+FO+jjRbhLfyjcwxumwn084p07eP4dnQFK4Rbu8SHcpv8p7JO/hDt4wrdwl/6PcA8r/An38eoN08gUsSncUif7LLRnef6utK1SU6hJMD5bC11oGzq9Ueujqg7J1LlYxdbkas6uzjKjSmt2OnLB1rlyNhrF4geRyZEigkGBuKkOS2gk2CNDCHvVvdQrpi0q+rVWmCDA+Cq1YKpokiGVxobJNY6sFQ48bUrXMa34Ws7kpLnMat4kIyv+77q3oxPRD7BtpkrMMOITX+SD5g75Pz0RXqgAAAB4nGNgYsAPmBiZGJkZmDINGBgAAw8ApwA=) format('woff');font-weight: normal;font-style: normal;}
  .roomCard {
  height: 180px;
}
  .roomCard:after {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }
  .roomCard:hover .card__cover {
   -webkit-transform: scale(1.15);
           transform: scale(1.15);
  }
  .roomCard .card__cover {
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .roomCard__title {
    font-weight: 500;
    font-size: 26px;
  }
  .roomCard__hint {
    font-weight: 400;
    font-size: 14px;
    font-family: Roboto;
    line-height: 16px;
  }
  .roomCard__price {
    line-height: 26px;
    font-weight: 900;
    font-size: 24px;
    font-family: 'Noah';
  }
  .roomCard__price > span {
      font-size: 17px;
      margin-right: 5px;
    }
  .roomCard__price:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: E8656d;
      font-weight: normal;
      content: '\E000';
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;
    }
  .roomCard__label {
    display: inline;
    font-weight: 500;
    line-height: .9;
    padding: 10px;
    font-size: 17px;
    color: #2F7DE1;
    z-index: 1;
    background: #ffffff;
    border-radius: 5px 5px 0 5px;
  }
  .roomCard__content {
    position: relative;
    z-index: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 100%;
    padding: 24px;
  }
