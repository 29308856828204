.contacts__section {
    margin: 40px 0 80px;
  }
  .contacts__address {
    margin: 0 0 30px;
  }
  @media(max-width: 996px) {
  .contacts__address {
      margin: 10px 0 30px
  }
    }
  @media(max-width: 1279px) {
  .contacts__column {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1
  }
    }
  .contacts__partnerLink {
    text-decoration: none;
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    color: #D8AB5E;
  }
  .contacts__partnerLink_image {
      -o-object-fit: cover;
         object-fit: cover;
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
  @media(max-width: 570px) {
  .contacts__partnerLink_image {
        display: none
    }
      }
  .contacts__partnerLink span {
      color: #272729;
      font-size: 18px;
      font-weight: 500;
      width: 100%;
      display: block;
    }