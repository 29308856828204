.documents {
  margin: 2em 0;
}

  .documents__title {
    font-size: 16px;
    font-weight: 900;
  }

  .documents__document {
    margin: 20px 30px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #272729;
    -webkit-transition: color ease .2s;
    transition: color ease .2s;
  }

  @media(max-width: 570px) {

  .documents__document {
      margin-right: 0
  }
    }

  .documents__document:first-child {
      margin-left: 0;
    }

  .documents__document:hover { 
      color: #D8AB5E;
    }

  .documents__document > img {
      width: 45px;
      height: 60px;
    }

  .documents__extra {
    margin-left: 16px;
    overflow: hidden;
    width: 300px;
  }

  @media(max-width: 570px) {

  .documents__extra {
      width: 180px
  }
    }

  .documents__extra > span {
      color: #78787A;
      line-height: 21px;
    }

  .documents__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }