.rest__siblings_list .restCard {
  margin: 1em 0;
}

.rest__heading {
  color: #ffffff;
}

@media(max-width: 1440px) {

.rest__heading {
    color: #272729;
    margin-top: 20px
}
  }