.orderSection {
  padding: 3.8em 0;
  margin: 0;
}
  @media(max-width: 570px) {.orderSection {
    padding: 2em 0
}
  }
  .orderSection__grid {
    width: 100%;
    display: grid;
    grid-template-columns:  320px 1fr;
    grid-gap: 20px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  @media(max-width: 996px) {
  .orderSection__grid {
      grid-template-columns:  1fr
  }
    }
  .orderSection__heading {
    font-size: .9em;
  }
  .orderSection__title {
    margin-bottom: 0;
    line-height: 1em;
  }
  .orderSection__subTitle {
    margin-top: .3em;
    color:#78787A;
    font-weight: 400;
    font-size: 1.1em;
  }