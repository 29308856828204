.room__header {
  display: block;
  color: #ffffff;
  font-size: 12px;
}
  @media(min-width: 769px){.room__header {
    font-size: 13px
}
  }
  @media(min-width: 1280px){.room__header {
    font-size: 14px
}
  }

.room__name {
  font-weight: 900;
  font-size: 3em;
  font-family: 'Noah';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; 
}

.room__hint { 
  margin: .8em 0;
  font-size: 1.1em;

}

.room__improved {
  margin-left: 1.5em;
  color: #D8AB5E;
}