@font-face { font-family: Ff8485; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAOwAAsAAAAABdAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPAAAAFYpvzZWY21hcAAAAYAAAABKAAABcOEoo6pnbHlmAAABzAAAACgAAAAoJ2mZsWhlYWQAAAH0AAAALgAAADYnD01vaGhlYQAAAiQAAAAYAAAAJABdAD9obXR4AAACPAAAAAgAAAAIADwAAGxvY2EAAAJEAAAABgAAAAYAFAAAbWF4cAAAAkwAAAAeAAAAIAENABVuYW1lAAACbAAAASkAAAIWm5e+CnBvc3QAAAOYAAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGSQY5zAwMrAwKDGoAUkOaC0DAMTAz8DkGBlZsAKAtJcUxgcHjA8YGCwAXJZwSQDAyOIAADJ0gVyeJztkLENgDAQA8/KQ4EYgSo1s1Cxv9gk+RjYIpbOkk9fPbAAJTmTAN2IkSut7AubfVB9E8M/tPZ37nCjlZndfXzLv3pBHRomCVEAAAABAAAAAAAgACIACAAANwcXIxUzBxc3GwIDEBADAgQiAgICAgIFAAB4nGNgZGBgAOLoqOem8fw2Xxm4GWyAIgyPX3RwIdMMCgxKQJKDgQnEAQAV/QjAAAB4nGNgZGBgsGFggJMKDIwMqIAJAA/aAJ0AAAAAADwAAAAAAAAAFAAAeJxjYGRgYGBi4GQA0SAWAwMXEDIw/AfzGQAJeAEjAAB4nGWQPW7CQBSEx2BIAlKCFCkps1UKIpmfkgNAT0GXwpi1MbK91npBossJcoQcIaeIcoIcKGPzaGAtP38zb97uygAG+IWHenm4bWq9WrihOnGb9CDsk5+FO+jjRbhLfyjcwxumwn084p07eP4dnQFK4Rbu8SHcpv8p7JO/hDt4wrdwl/6PcA8r/An38eoN08gUsSncUif7LLRnef6utK1SU6hJMD5bC11oGzq9Ueujqg7J1LlYxdbkas6uzjKjSmt2OnLB1rlyNhrF4geRyZEigkGBuKkOS2gk2CNDCHvVvdQrpi0q+rVWmCDA+Cq1YKpokiGVxobJNY6sFQ48bUrXMa34Ws7kpLnMat4kIyv+77q3oxPRD7BtpkrMMOITX+SD5g75Pz0RXqgAAAB4nGNgYsAPmBiZGJkZmDINGBgAAw8ApwA=) format('woff');font-weight: normal;font-style: normal;}
  .voucherBookingBtn {
  padding-left: 2em;
  padding-right: 1em;
  margin: 1.8em 0 1.2em;
  background: #D8AB5E;
  border: 1px solid #D8AB5E;
  color: #ffffff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  white-space: nowrap;
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
}
  .voucherBookingBtn:hover {
    -webkit-box-shadow: 0 5px 15px 0 rgba(72,93,200,0.20);
            box-shadow: 0 5px 15px 0 rgba(72,93,200,0.20);
  }
  .voucherBookingBtn:hover:after {
      left: .2em;
    }
  .voucherBookingBtn:active {
    -webkit-box-shadow: 0 5px 24px rgba(238, 105, 162, 0.3);
            box-shadow: 0 5px 24px rgba(238, 105, 162, 0.3);
  }
  @media(max-width: 570px) {.voucherBookingBtn {
    width: 100%
}
  }
  @media(min-width: 769px) {.voucherBookingBtn {
    margin: 1.4em 0 1.2em
}
  }
  @media(min-width: 997px) {.voucherBookingBtn {
    margin: 2.6em 0 1.8em
}
  }
  .voucherBookingBtn:after {
    position: relative;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Ff8485;
    font-weight: normal;
    content: '\E000';
    font-size: 50px;
    color: #ffffff;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    left: 0;
  }