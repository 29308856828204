.breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 300;
  -webkit-appearance: none;
  max-width: 100%;
  overflow-x: auto;
  z-index: 1;
}

.breadcrumb {
  list-style: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin: 0 .5em;
}

.breadcrumb > span {
    margin-right: 14px;
    color: #ffffff;
    font-weight: 500;
  }

.breadcrumb > a {
    font-family: 'Noah';
    color: #ffffff;
    font-weight: 900;
    font-size: 16px;
    text-decoration: none;
  }

.breadcrumb:first-child {
    margin-left: 0;
  }

.breadcrumb:last-child {
    margin-right: 0;
  }
