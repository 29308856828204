@media(max-width: 570px) {.mainAdvantages {
    margin: 40px 0 20px
}
  }
  .mainAdvantages__button {
    color: #D8AB5E;
  }
  .mainAdvantages__grid {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  @media(max-width: 996px) {
  .mainAdvantages__grid {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
  }
    }
  .mainAdvantages__footer {
    position: relative;
    top: -45px;
    margin: 0 0 0 8%;
  }
  @media(max-width: 570px) {
  .mainAdvantages__footer {
      margin: 0 auto
  }
    }
  .mainAdvantages__column {
    width: 50%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  @media(min-width: 769px) {
  .mainAdvantages__column {
      -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
              order: 0
  }
    }
  .mainAdvantages__column.mainAdvantages__column--right {
      width: 47%;
      -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
              order: 0;
    }
  @media(min-width: 769px) {
  .mainAdvantages__column.mainAdvantages__column--right {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1
    }
      }
  @media(max-width: 996px) {
  .mainAdvantages__column {
      width: 100%
  }
      .mainAdvantages__column.mainAdvantages__column--right {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  .mainAdvantages__img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .mainAdvantages__cover {
    width: 100%;
    max-width: 570px;
    height: 320px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
  .mainAdvantages__cover:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: #272729;
      opacity: .12;
    }
  @media(max-width: 570px) {
  .mainAdvantages__cover {
      max-width: none;
      width: calc(100% + 30px);
      height: 280px;
      left: -15px;
      overflow: initial
  }
    }
  .mainAdvantages__position {
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 700;
    color: #272729;
    margin-top: 20px;
  }
  @media(max-width: 570px) {
  .mainAdvantages__position {
      margin-top: 10px
  }
    }
  .mainAdvantages__quote {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    font-family: 'Roboto';
    max-width: 550px;
    margin-top: 95px;
  }
  @media(max-width: 996px) {
  .mainAdvantages__quote {
      margin-top: 70px
  }
    }
  @media(max-width: 769px) {
  .mainAdvantages__quote {
      margin-top: 40px
  }
    }
  @media(max-width: 570px) {
  .mainAdvantages__quote {
      margin-top: 25px
  }
    }
  .mainAdvantages__content {
    margin-top: 84px;
  }
  @media(max-width: 996px) {
  .mainAdvantages__content {
      margin-top: 40px
  }
    }
  @media(max-width: 570px) {
  .mainAdvantages__content {
      margin-top: 20px
  }
    }
  .mainAdvantages__items {
    margin-bottom: 40px;
  }
  .mainAdvantages__item {
    font-size: 34px;
    line-height: 40px;
    font-family: 'Noah';
    font-weight: 400;
    margin-top: 52px;
  }
  .mainAdvantages__item:first-child {
      margin-top: 25px;
    }
  .mainAdvantages__item span {
      color: #D8AB5E
    }
  @media(max-width: 1279px) {
  .mainAdvantages__item {
      font-size: 26px;
      line-height: 40px
  }
    }
  @media(max-width: 769px) {
  .mainAdvantages__item {
      max-width: 500px;
      margin-top: 30px
  }
      .mainAdvantages__item:first-child {
        margin-top: 0px;
      }
    }
  @media(max-width: 570px) {
  .mainAdvantages__item {
      font-size: 22px;
      line-height: 26px;
      margin-top: 20px
  }
    }