.priceFullTable {
  width: 100%;
  font-family: 'Noah';
  margin: 0 0 2em 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #ffffff;
  -webkit-box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
          box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
}
  .priceFullTable__container {
    overflow: auto;
    width: 100%;
  }
  .priceFullTable__tbody {
    background: #F8F8FC;
  }
  .priceFullTable__tfoot .proceduresTable__td {
      padding-top: 2.5em;
    }
  .priceFullTable__tr:nth-child(2) {
      background: rgba(49, 49, 49, 0.12);
    }
  .priceFullTable__tr:nth-child(8) {
      background: rgba(49, 49, 49, 0.12);
    }
  .priceFullTable__tr:nth-child(14) {
      background: rgba(49, 49, 49, 0.12);
    }
  .priceFullTable__tr:nth-child(20) {
      background: rgba(49, 49, 49, 0.12);
    }
  .priceFullTable__tr:nth-child(26) {
      background: rgba(49, 49, 49, 0.12);
    }
  .priceFullTable__tr:nth-child(3) {
      background: transparent;
    }
  .priceFullTable__td { 
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: .5em 0;
    width: 50px;
    color: #D8AB5E;
    -webkit-transition: all ease .3s;
    transition: all ease .3s;
    opacity: .85;  
  }
  .priceFullTable__th { 
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: .5em 0;
    width: 50px;
    color: #D8AB5E;
    -webkit-transition: all ease .3s;
    transition: all ease .3s;
    opacity: .85;  
  }
  .priceFullTable__td.priceFullTable__th--header {
      color: #272729;
      font-size: 16px;
    }
  .priceFullTable__th.priceFullTable__th--header {
      color: #272729;
      font-size: 16px;
    }
  .proceduresTable__tr:hover .priceFullTable__td, .proceduresTable__tr:hover .priceFullTable__th {
      opacity: 1;
    }
  .priceFullTable__td:nth-child(1){
      min-width: 75%;
      opacity: 1;
      padding: .5em 1.5em;
      position: relative;
      text-align: left;
      color: #272729;
      font-size: 14px;
      font-weight: 500;
    }
  .priceFullTable__th:nth-child(1){
      min-width: 75%;
      opacity: 1;
      padding: .5em 1.5em;
      position: relative;
      text-align: left;
      color: #272729;
      font-size: 14px;
      font-weight: 500;
    }
  @media(max-width: 996px) {
  .priceFullTable__td:nth-child(1),.priceFullTable__th:nth-child(1){
        font-size: 16px
    }
      }
  .priceFullTable__td > span, .priceFullTable__th > span {
      font-weight: 400;
    }
  .priceFullTable__th {
    padding-top: .5em;
    vertical-align: bottom;
    font-weight: 900;
  }
  .priceFullTable__td {
    color: #D8AB5E;
    font-size: 16px;
    font-family: 'Roboto';
  }
  .priceFullTable__td:nth-child(1){
      color: #272729;
    }
  .priceFullTable__hint { 
    color: #78787A;
    font-weight: 400;
    font-size: .9em;
    line-height: 1.1em;
  }
  .priceFullTable__th {
    font-family: 'Roboto';
    font-size: 14px;
    -webkit-transition: all ease .3s;
    transition: all ease .3s;
  }
  .priceFullTable__th > span {
      font-size: 16px;
      position: relative;
      top: -4px;
    }
  .priceFullTable__th:not(:nth-child(1)){
      opacity: .85;
    }
  .priceFullTable__th--vouchers {
      font-size: 16px;
      font-weight: 500;
      position: relative;
    }
  .priceFullTable__th--vouchers:not(:nth-child(1)){
        opacity: 1;
      }
  .priceFullTable__th--vouchers:first-child:before {
        opacity: 0;
      }
  .priceFullTable__th--vouchers:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        background: #eef1fd;
        display: block;
        top: 0;
        left: -2px;
      }