.main__rest {
  margin-bottom: 0;
  padding-bottom: 20px;
  position: relative;
}
  .main__rest:before { 
    position: absolute;
    background: #F5F5F7;
    width: 100%;
    height: 40%;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
  }
  .main__rest_grid {
    width: 100%;
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 14% 74%;
    grid-column-gap: 164px;
    grid-row-gap: 24px;
  }
  @media(max-width: 1279px) {
  .main__rest_grid {
      grid-column-gap: 110px;
      grid-template-columns: none
  }
    }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main__rest_grid {
      display: block;
      width: 100%
  }
      .main__rest_grid > a {
        width: calc(16.65% - 25px);
      }
      .main__rest_grid > a:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .main__rest_grid > a:last-child {
        margin-right: 0;
      }
      .main__rest_grid > a:nth-child(2n) {
        margin-right: 0;
        float: right;
      }
      .main__rest_grid > a:nth-child(2n + 1) {
        clear: both;
      }
    }
  @media(max-width: 996px) {
  .main__rest_title {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start
  }
    }
  .main__rest_hint {
    line-height: 17px;
    margin-top: 10px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
  }
  .main__rest_link {
    display: block;
    margin-top: 16%;
    font-weight: 500;
    z-index: 1;
  }
  @media(max-width: 996px) {
  .main__rest_link {
      margin-top: 4%
  }
    }

