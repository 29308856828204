.treatment__article {
    background: #ffffff;
    padding: 30px 40px;
    border-radius: 5px;
}
    @media(max-width: 769px) {.treatment__article {
      margin-bottom: 40px
}
    }

.treatmentArticle__section {
  margin: 60px 0 50px;
}

@media(min-width: 769px) {

.treatment__grid {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 28px
}
  }

.treatment__button {
  padding: 5px 21px 5px 21px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #D8AB5E;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  font-family: 'Noah';
  text-decoration: none;
  border-radius: 5px;
}

.treatment__button:hover {
    background: linear-gradient(162.86deg, #DE62A0 0%, #F2839F 100%);
    color: #ffffff;
  }