.partners__grid {
    display: grid;
    width: 100%;
    grid-template-columns: 48% 48%;
    grid-column-gap: 40px;
  }
    @media(max-width: 769px) {.partners__grid {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.partners__grid {
      display: block;
      width: 100%
  }
      .partners__grid > div {
        width: calc(49.95% - 15px);
        margin-bottom: 30px;
      }
      .partners__grid > div:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .partners__grid > div:last-child {
        margin-right: 0;
      }
      .partners__grid > div:nth-child(2n) {
        margin-right: 0;
        float: right;
      }
      .partners__grid > div:nth-child(2n + 1) {
        clear: both;
      }
    }
  .partners__content {
    margin-top: 0
  }