.roomSiblings__list {
  margin-top: 28px;
  display: grid;
  /* grid-template-columns: repeat(auto-fill, calc(50% - 28px) calc(50% - 28px)); */
  grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
  grid-gap: 28px;
}

  @media(max-width: 570px) {.roomSiblings__list {
    grid-template-columns: 1fr
}
  }

  @media(min-width: 571px) {.roomSiblings__list {
    max-height: 180px;
    overflow: hidden
}
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.roomSiblings__list {
    display: block;
    width: 100%
}
    .roomSiblings__list > a {
      width: calc(24.975% - 22.5px);
      margin-bottom: 30px;
    }
    .roomSiblings__list > a:nth-child(1n) {
      float: left;
      margin-right: 30px;
      clear: none;
    }
    .roomSiblings__list > a:last-child {
      margin-right: 0;
    }
    .roomSiblings__list > a:nth-child(4n) {
      margin-right: 0;
      float: right;
    }
    .roomSiblings__list > a:nth-child(4n + 1) {
      clear: both;
    }
  }