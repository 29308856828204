.sale__description {
    width: 65%;
  }
    
    @media(max-width: 769px) {.sale__description {
      width: calc(100% - 84px)
  }
    }
    
    @media(max-width: 570px) {.sale__description {
      margin-right: 0;
      width: 100%
  }
    }

  .sale__column {
    width: 35%;
    margin-left: 20px;
  }

  @media(max-width: 769px) {

  .sale__column {
      width: 100%;
      margin: 0
  }
    }

  .sale__grid {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  @media(max-width: 769px) {

  .sale__grid {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
  }
    }

  @media(min-width: 1280px) {

  .sale__title {
      color: #ffffff
  }
    }

  @media(max-width: 1279px) {

  .sale__siblings {
      width: 100%;
      margin-left: 0
  }
    }