.blogCard {
  -webkit-box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
          box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
}
  .blogCard__content {
    position: relative;
  }
  .blogCard__title {
    font-size: 20px;
    font-family: 'Noah';
    font-weight: 900;
    line-height: 22px;
    margin-top: 6px;
  }
  .blogCard__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .blogCard__description {
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 21px;
    margin-top: 12px;
    height: 40px;
    overflow: hidden;
  }
  .blogCard__cover {
    width: 100%;
    height: 240px;
  }
  .blogCard__cover > img {
      -o-object-fit: cover;
         object-fit: cover;
      width: 100%;
      height: 100%;
      -o-object-position: 50% 55%;
         object-position: 50% 55%;
    }
  .blogCard__link {
    margin-top: 16px;
    font-size: 14px;
    font-family: 'Roboto';
    text-decoration: none;
  }
  .blogCard__link:after {
      color: #272729;
    }
  .blogCard:hover .blogCard__link {
      color: #D8AB5E;
    }
  .blogCard__date {
    line-height: 1em;
    font-size: 12px;
    color: #78787A;
  }
  @media(max-width: 468px) {
  .blogCard__date {
      display: none
  }
    }
