.voucherReviewsAndSiblings__grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2em;
  }
    @media(min-width: 571px) {.voucherReviewsAndSiblings__grid {
      // grid-template-columns: calc(50% - 2em) 1fr;
      grid-template-columns: minmax(100% 1fr)
  }
    }