.license__list {
    display: block;
    width: 100%;
    margin-bottom: 40px;
  } 
  .license__title {
    font-weight: 300;
    font-size: 16px;
    margin-top: 15px;
  }
.image_grid {
  width: calc(100% + 1em);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -.5em;
}
@media(max-width: 570px){
.image_grid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow: auto
}
  }
.image_grid__item {
    margin: .5em;
    width: 125px;
    overflow: hidden;
    cursor: pointer;
  }
.image_grid__link > img {
      width: 100%;
    }


