.priceList__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    margin-bottom: 1em;
  }
  .priceList__title {
    font-family: 'Noah';
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    color: #D8AB5E;
    margin-bottom: .5em;
  }
  .priceList__periods {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .priceList__isError {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 1.1em; 
    color: #78787A;
    font-weight: 300;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 3em 2em;
    background: #ffffff;
    border-radius: 4px;
    text-align: center;
    font-style: italic;
  }
  @media(max-width: 570px) {
  .priceList__isError {
      font-size: 1em
  }
    }
  .priceList__isError > div {
      width: 85%;
    }
  .priceList__isError:before {
      font-style: normal;
      content: '!';
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      color: #272729;
      opacity: .85;
      font-weight: 500;
      font-family: 'Alegreya';
      font-size: 20px;
      background: #F5F5F7;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 1em;  
    }