@font-face { font-family: H89c9d; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAO8AAsAAAAABdAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqRzbGY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAACgAAAAoTOokF2hlYWQAAAH4AAAALgAAADYnek3PaGhlYQAAAigAAAAbAAAAJADxAEBobXR4AAACRAAAAAgAAAAIAGQAAGxvY2EAAAJMAAAABgAAAAYAFAAAbWF4cAAAAlQAAAAeAAAAIAENABVuYW1lAAACdAAAASkAAAIWm5e+CnBvc3QAAAOgAAAAGwAAACxqOAEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGQwYpzAwMrAwGDP4AYk+aC0AQMLgyQDAxMDKzMDVhCQ5prC4PCA4QEDQwqQywkmGRgYQQQA+uoGagAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAAAAQAAAAAAiwBiAAgAADcHFyMVMwcXN2kKEG9vEAoiYgoRDhAKIQAAeJxjYGRgYABinp8Xjsfz23xl4GZIAYowPH7RwYZMM3QzJAFJDgYmEAcART8KVgAAeJxjYGRgYEhhYICQ/28ydDMwMqACJgBIQgMwAAAAAAAAZAAAAAAAAAAUAAB4nGNgZGBgYGLgZADRIBYDAxcQMjD8B/MZAAl4ASMAAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BigABGBuyAiZGJkZmBKdOAgQEAAzIAqAA=) format('woff');font-weight: normal;font-style: normal;}
      .voucherCard:hover .voucherCard__button {
      color: #ffffff;
    }
      .voucherCard:hover .voucherCard__button:after {
        opacity: 0.8;
      }
    .voucherCard:hover .voucherCard__number {
      color: #ffffff;
    }
    .voucherCard:hover .voucherCard__extra {
      color: #ffffff;
    }
    .voucherCard:hover .voucherCard__more {
      color: #ffffff;
    }
    .voucherCard:hover .voucherCard__more:after {
      color: #ffffff;
    }
  .voucherCard__title {
    display: block;
    font-family: 'Noah';
    font-size: 25px;
    line-height: 24px;
    font-weight: 900;
    margin: 20px 0 12px;
    text-decoration: none;
    color: #272729;
  }
  .voucherCard__description {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .voucherCard__cover {
    width: 100%;
    height: 260px;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }
  .voucherCard__cover:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #272729;
      opacity: .1;
    }
  .voucherCard__cover img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }
  .voucherCard__button {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 8px;
    padding: 13px 20px;
    overflow: hidden;
    text-decoration: none;
  }
  .voucherCard__button:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.12;
      z-index: 1;
      background: #D8AB5E;
      -webkit-transition: .2s ease-out;
      transition: .2s ease-out;
    }
  .voucherCard__more {
    color: #D8AB5E;
    font-size: 15px;
    margin-right: 12px;
    z-index: 2;
  }
  .voucherCard__more:after {
      position: relative;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: H89c9d;
      font-weight: normal;
      content: '\E000';
      font-size: 10px;
      color: #D8AB5E;
      top: 1px;
      left: 10px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
    }
  .voucherCard__price {
    color: #D8AB5E;
    z-index: 2;
  }
  .voucherCard__number {
    font-size: 17px;
    line-height: 22px;
    font-weight: 600;
    z-index: 2;
    margin-bottom: -4px;
  }
  .voucherCard__number span {
      font-size: 18px;
    }
  .voucherCard__extra {
    font-family: 'Noah';
    line-height: 23px;
    font-size: 14px;
  }
  .voucherCard__extra span {
      margin: 0 2px;
      display: inline-block;
    }

.voucherCardShort {
  border: 1px solid #F0F0F2;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.voucherCardShort li {
    line-height: 12px;
  }

.voucherCardShort li:before {
      width: 3px;
      height: 3px;
      left: -16px;
      top: 3px;
    }

.voucherCardShort__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

.voucherCardShort__cover {
    height: 45px;
    width: 60px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 8px;
    margin-right: 15px;
  }

.voucherCardShort__type {
    color: #78787A;
    font-size: 14px;
    font-weight: 300;
  }

.voucherCardShort__content {
    position: relative;
    width: 100%;
  }

.voucherCardShort__button {
    position: absolute;
    bottom: 0; 
  }

.voucherCardShort__button:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: H89c9d;
      font-weight: normal;
      content: '\E000';
      font-size: 58px;
      margin: 30px 0 -21px 16px;
      color: #DDDFE0;
      -webkit-transition: all .1s ease-out;
      transition: all .1s ease-out;
    }

.voucherCardShort__list {
    color: #272729;
    font-weight: 300;
    padding-left: 16px;
    margin-bottom: 0;
  }

.voucherCardShort__price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-family: 'Noah';
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    color: #78787A;
  }

.voucherCardShort__price > span {
      font-family: 'Noah';
      color: #272729;
      margin: 0 8px;
      font-weight: bold;
      font-size: 24px;
      line-height: 26px;
    }

.voucherCardShort:hover .button {
      color: #D8AB5E;
    }

.voucherCardShort:hover .card__title {
      color: #D8AB5E;
    }

.voucherCardShort:hover .voucherCard__button:after {
      color: #D8AB5E;
      margin-left: 20px;
    }