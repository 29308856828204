.vacancyExcursions__items {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
  @media(max-width: 570px) {.vacancyExcursions__items {
    margin-top: 15px;
    grid-row-gap: 10px
}
  }


