.roomWelcome {
  position: relative;
  padding-top: 1em;
  margin: 0;
}
  @media(min-width: 769px) {.roomWelcome {
    padding-top: 70px
}
  }
  @media(min-width: 997px) {.roomWelcome {
    padding-top: 90px
}
  }
  @media(min-width: 997px) {.roomWelcome {
    padding-top: 100px
}
  }
  .roomWelcome__grid {
    width: 100%;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;
  }
  @media(min-width: 769px) {
  .roomWelcome__grid {
      grid-template-columns: minmax(275px, 1fr);
      grid-auto-flow: dense;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: start;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start
  }
      .roomWelcome__grid .roomWelcome__card { grid-column: 1 }
      .roomWelcome__grid .roomWelcome__gallery { grid-column: 2}
    }
  @media(min-width: 997px) {
  .roomWelcome__grid {
      grid-template-columns: 35% 1fr
  }
    }
  @media(min-width: 1280px) {
  .roomWelcome__grid {
      grid-template-columns: 30% 1fr
  }
    }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .roomWelcome__grid {
      display: block;
      width: 100%
  }
      .roomWelcome__grid > div {
        margin-bottom: 30px;
      }
      .roomWelcome__grid > .roomWelcome__card {
        width: calc(33.3% - 20px);
      }
      .roomWelcome__grid > .roomWelcome__card:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .roomWelcome__grid > .roomWelcome__card:last-child {
        margin-right: 0;
      }
      .roomWelcome__grid > .roomWelcome__card:nth-child(12n) {
        margin-right: 0;
        float: right;
      }
      .roomWelcome__grid > .roomWelcome__card:nth-child(12n + 1) {
        clear: both;
      }
      .roomWelcome__grid > .roomWelcome__gallery {
        width: calc(66.6% - 10px);
      }
      .roomWelcome__grid > .roomWelcome__gallery:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .roomWelcome__grid > .roomWelcome__gallery:last-child {
        margin-right: 0;
      }
      .roomWelcome__grid > .roomWelcome__gallery:nth-child(12n) {
        margin-right: 0;
        float: right;
      }
      .roomWelcome__grid > .roomWelcome__gallery:nth-child(12n + 1) {
        clear: both;
      }
    }
  .roomWelcome__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 470px;
    width: 100%;
    background: #566E9E;
  }
  .roomWelcome__bg:after {
      content: '';
      background-image: url('/images/patern--right.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 100%;
      right: 0;
      top: 0;
      display: block;
    }
  @media(max-width: 570px) {
  .roomWelcome__bg:after {
        display: none
    }
      }
  .roomWelcome__bg:before {
      content: '';
      background-image: url('/images/patern--left.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 242px;
      left: 0;
      top: 0;
      display: block;
    }
  @media(min-width: 571px){
  .roomWelcome__bg {
      height: 535px
  }
    }
  @media(min-width: 769px){
  .roomWelcome__bg {
      height: 337px
  }
    }
  @media(min-width: 997px){
  .roomWelcome__bg {
      height: 327px
  }
    }