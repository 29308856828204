.openMenu__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 40px 0 20px;
  }
    @media(max-width: 570px) {.openMenu__items {
      margin: 20px 0 0
  }
    }
  .openMenu__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .openMenu__list {
    width: 70%;
    height: auto;
    position: absolute;
    left: 35%;
    top: -4px;
    opacity: 1;
    -webkit-transition: opacity .3s cubic-bezier(.785,.135,.15,.86),visibility .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform 0s cubic-bezier(.785,.135,.15,.86) .3s;
    transition: opacity .3s cubic-bezier(.785,.135,.15,.86),visibility .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform 0s cubic-bezier(.785,.135,.15,.86) .3s;
    transition: transform 0s cubic-bezier(.785,.135,.15,.86) .3s,opacity .3s cubic-bezier(.785,.135,.15,.86),visibility .3s cubic-bezier(.785,.135,.15,.86);
    transition: transform 0s cubic-bezier(.785,.135,.15,.86) .3s,opacity .3s cubic-bezier(.785,.135,.15,.86),visibility .3s cubic-bezier(.785,.135,.15,.86),-webkit-transform 0s cubic-bezier(.785,.135,.15,.86) .3s;
    -webkit-transform: translate3d(-40px,0,0);
            transform: translate3d(-40px,0,0);
    visibility: visible;
  }
  @media(max-width: 1279px) {
  .openMenu__list {
      display: none
  }
    }
  .openMenu__link {
    font-family: 'Noah';
    font-weight: 900;
    color: #272729;
    text-decoration: none;
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 25px;
    position: relative;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    width: 24%;
  }
  @media(max-width: 1279px) {
  .openMenu__link {
      width: 100%
  }
    }
  .openMenu__link sub {
      font-size: 18px;
      line-height: 52px;
      font-weight: 900;
      top: -1em;
      left: 7px;
    }
  @media(max-width: 570px) {
  .openMenu__link {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 20px
  }
      .openMenu__link sub {
        font-size: 16px;
        top: -0.8em;
        left: 5px;
      }
    }
  .openMenu__link:hover {
      color: #D8AB5E;
    }
  .openMenu__link:hover .openMenu__list {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-transition: opacity .5s ease,visibility 1s ease,-webkit-transform 1s cubic-bezier(.075,.82,.165,1);
        transition: opacity .5s ease,visibility 1s ease,-webkit-transform 1s cubic-bezier(.075,.82,.165,1);
        transition: transform 1s cubic-bezier(.075,.82,.165,1),opacity .5s ease,visibility 1s ease;
        transition: transform 1s cubic-bezier(.075,.82,.165,1),opacity .5s ease,visibility 1s ease,-webkit-transform 1s cubic-bezier(.075,.82,.165,1);
      }
  .openMenu__link--sub {
      width: auto;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      font-family: 'Roboto';
      margin: 0 50px -20px 0;
      display: inline-block;
    }
  .openMenu__link--sub:last-child {
        margin-right: 0;
      }