.profileCard .card__content {
    padding: 2em;
  }
    @media(min-width: 1440px) {.profileCard .card__content {
      padding: 2em 2.5em
  }
    }

  .profileCard .card__header {
   -webkit-box-pack: start;
       -ms-flex-pack: start;
           justify-content: flex-start;
  }

  .profileCard__icon {
    width: 50px;
    height: 50px;
    margin-right: 18px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  @media(max-width: 996px) {

  .profileCard__icon {
      width: 45px;
      height: 45px;
      margin-right: 15px
  }
    }

  .profileCard__description {
    margin-top: 20px;
    line-height: 21px;
    font-weight: 300;
    /* height: 105px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    /* @media(--max-small-phone) {
        display: none;
    } */
  }

  .profileCard__programmList {
    cursor: pointer;
  }

  .profileCard__programmList--opened {
      background: #F0F0F2;
    }

  .profileCard__programmList:after {
      display: block;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #F0F0F2;
    }

  .profileCard__programmList:before {
      display: block;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: #F0F0F2;
    }

  .profileCard__includeProgramm {
    color: #D8AB5E;
    font-weight: 400;
  }

  .profileCard__toogle {
    font-weight: 400;
    font-size: 12px;
    color: #78787A;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }

  .profileCard__programmList:hover .profileCard__toogle {
      color: #D8AB5E;
    }

  .profileCard__more {
    color: #272729;
    text-decoration: none;
    font-weight: 500;
  }

  .profileCard__more:hover {
      color: #D8AB5E;
    }

  .profileCard__procedures {
    font-weight: 500;
  }

  .profileCard__procedures > span {
      margin-right: 12px;
      font-family: 'Noah';
      font-weight: 700;
      font-size: 34px;
      color: #D8AB5E;
      line-height: 30px;
    }