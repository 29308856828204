@font-face { font-family: vf39b1; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAPMAAsAAAAABgQAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPAAAAFYpvzZWY21hcAAAAYAAAABKAAABcOEoo6pnbHlmAAABzAAAAEQAAABcOF2XeWhlYWQAAAIQAAAAKwAAADYnLE15aGhlYQAAAjwAAAAbAAAAJAB7AD5obXR4AAACWAAAAAgAAAAIADwAAGxvY2EAAAJgAAAABgAAAAYALgAAbWF4cAAAAmgAAAAeAAAAIAENAChuYW1lAAACiAAAASkAAAIWm5e+CnBvc3QAAAO0AAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGSQY5zAwMrAwKDGoAUkOaC0DAMTAz8DkGBlZsAKAtJcUxgcHjA8YGCwAXJZwSQDAyOIAADJ0gVyeJztkLENgDAQA8/KQ4EYgSo1s1Cxv9gk+RjYIpbOkk9fPbAAJTmTAN2IkSut7AubfVB9E8M/tPZ37nCjlZndfXzLv3pBHRomCVEAAHicY2BkAAFbBhsGaQYGe0YzEzUlfkZ1NSU2EXlGdjYRMSN7RnExIzMTdWUJRiZmRgkJRmYmEAlny2GIQNgMDAA1xAWjeJxjYGRgYABiixMvz8bz23xl4GawAYowPH7RwYRMM9iCxTkYwDwAPhAJzAB4nGNgZGBgsGFggJD//zPYMjAyoAImADzWArgAAAAAAAA8AAAAAAAAAC4AAHicY2BkYGBgYpBhANEgFgMDFxAyMPwH8xkAC1MBNgAAeJxlkD1uwkAUhMdgSAJSghQpKbNVCiKZn5IDQE9Bl8KYtTGyvdZ6QaLLCXKEHCGniHKCHChj82hgLT9/M2/e7soABviFh3p5uG1qvVq4oTpxm/Qg7JOfhTvo40W4S38o3MMbpsJ9POKdO3j+HZ0BSuEW7vEh3Kb/KeyTv4Q7eMK3cJf+j3APK/wJ9/HqDdPIFLEp3FIn+yy0Z3n+rrStUlOoSTA+WwtdaBs6vVHro6oOydS5WMXW5GrOrs4yo0prdjpywda5cjYaxeIHkcmRIoJBgbipDktoJNgjQwh71b3UK6YtKvq1VpggwPgqtWCqaJIhlcaGyTWOrBUOPG1K1zGt+FrO5KS5zGreJCMr/u+6t6MT0Q+wbaZKzDDiE1/kg+YO+T89EV6oAAAAeJxjYGLAD5gYmRiZGZgyDRgYAAMPAKcA) format('woff');font-weight: normal;font-style: normal;}
  .menu {
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 9;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: all;
  opacity: 1;
  visibility: visible;
}
  .menu__heading {
    font-family: 'Alegreya';
    position: relative;
    font-weight: bold;
    font-size: 32px;
    line-height: 34px;
  }
  .menu__heading:after {
      content: '';
      width: 20%;
      height: 3px;
      background: #D8AB5E;
      display: block;
      position: absolute;
      z-index: 1;
      bottom: -11px;
      left: 0;
    }
  .menu__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding-top: 40px;
  }
  .menu__links > a {
      font-size: 14px;
      font-weight: 500;
      width: calc(100% - 20px);
      text-decoration: none;
      color: #404040;
      margin-bottom: 16px;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      position: relative;
      left: 20px;
    }
  .menu__links > a:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: -21px;
        top: 50%;
        margin-top: -6px;
        opacity: .9;
        background: transparent;
        -webkit-transition: background .35s;
        transition: background .35s;
        background: #4e89ff;
        content: '';
      }
  .menu__links > a:after {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        opacity: .9;
        top: 50%;
        margin-top: -5px;
        background: #4e89ff;
        content: '';
      }
  .menu__links > a:hover {
        color: #D8AB5E;
      }
  .menu__links > a:hover:after {
          background: #23c748;
          -webkit-transition: background .35s,-webkit-transform .35s;
          transition: background .35s,-webkit-transform .35s;
          transition: transform .35s,background .35s;
          transition: transform .35s,background .35s,-webkit-transform .35s;
          -webkit-transform: scale(.8);
                  transform: scale(.8);
        }
  .menu__close {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 1;
    color: #272729;
    padding: 40px;
    -webkit-transition: .5s ease;
    transition: .5s ease;
  }
  .menu__close:before {
      margin-bottom: 4px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: vf39b1;
      font-weight: normal;
      content: '\E000';
      color: #272729;
      font-size: 25px;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
    }
  @media(min-width: 997px) {
  .menu__close {
      position: absolute;
      right: 0;
      top: 0
  }
    }
  @media(max-width: 769px) {
  .menu__close {
      padding: 15px
  }
      .menu__close:before {
        font-size: 15px;
      }
    }
  .menu__header {
    padding: 13px 0;
    z-index: 1;
  }
  @media(min-width: 997px) {
  .menu__header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      height: 80px
  }
    }
  .menu__header_logo {
      z-index: 1;
      width: 230px;
      height: 150px;
    }
  @media(max-width: 769px) {
  .menu__header_logo {
        width: 170px;
        height: 70px
    }
      }
  .menu__header_logo > img {
        width: 100%;
        height: 100%;
      }
  .menu__body {
    margin: 20px 0 20px;
    z-index: 1;
  }
  @media(min-width: 1280px) {
  .menu__body {
      height: calc(100% - 257px);
      position: relative;
      overflow: hidden;
      margin: 60px 0 0
  }
    }
  .menu__footer {
    width: 100%;
    margin-bottom: 20px;
    z-index: 1;
  }
  @media(min-width: 997px) {
  .menu__footer {
      height: 50px;
      margin-bottom: 0
  }
    }
  .menu__copyright {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  @media(max-width: 769px) {
  .menu__copyright {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
  }
    }
  .menu__copyright_links {
      padding-top: 10px;
      z-index: 1;
    }
  .menu__copyright_links a {
        font-size: 14px;
        font-weight: 300;
        text-decoration: none;
        margin-left: 5px;
        color: #272729;
      }
  .menu__copyright_text {
      z-index: 1;
      color: #272729;
      font-size: 14px;
      padding-top: 10px;
      font-weight: 300;
    }

.menuHeader__container {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

@media(min-width: 997px) {

.menuHeader__container {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
  }
    }