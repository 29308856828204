.about__description {
  width: 100%;
}

  .about__description > p {
    margin: 0;
  }

  @media(max-width: 1279px) {

  .about__description > p {
      margin-top: 20px
  }
    }