.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    width: 100%;
    background: #ffffff;
    margin: 15px 0;
    margin-left: 0;
    position: relative;
}
    .header.header--line:before {
            content: '';
            position: absolute;
            bottom: -28px;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: #F0F0F2;
        }
    @media(max-width: 570px) {
    .header.header--line:before {
                bottom: -15px
        }
            }
    @media(min-width: 571px) {.header {
        margin: 28px 0
}
    }
    .header__logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 160px;
    }
    .header__logo img {
            width: 100%;
            -o-object-fit: contain;
               object-fit: contain;
            height: 100%;
        }
    @media(min-width: 571px) {
    .header__logo {
            width: 195px
    }
        }
    .header__left {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        overflow: hidden;
    }
    .header__right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .header__navigation {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow-x: auto;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        position: relative;
    }
    @media(max-width: 996px) {
    .header__navigation {
            display: none
    }
        }
    .header__navigation > a {
            color: #333333;
            font-size: 16px;
            font-weight: 400;
            font-family: 'Roboto';
            text-decoration: none;
            margin-right: 36px;
            -webkit-transition: all .2s ease;
            transition: all .2s ease;
            white-space: nowrap;
        }
    .header__navigation > a:last-child {
                margin: 0;
            }
    .header__navigation > a:hover {
                color: #D8AB5E;
            }
    @media(max-width: 1279px) {
    .header__navigation > a {
                margin-right: 19px
        }
            }