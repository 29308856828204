.main__grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 2em;
}

  @media(min-width: 769px) {.main__grid {
    grid-template-columns: 1fr 1fr
}
  }

