.procedureTable__order {
  display: block;
  font-size: 14px;
  color: #78787A;
  font-weight: 300;
  text-decoration: none;

}
  .procedureTable__order_text {
    text-align: center;
    width: 100%;
    margin-top: 1em;
  }
  .procedureTable__order_square {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all ease .3s;
    transition: all ease .3s;
    width: 40px;
    height: 40px;
    background: rgba(115, 161, 255, .2);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-radius: 2px;
    margin: 0;
    color: #D8AB5E;
}
  .procedureTable__order_square:before {
      font-size: 30px;
      font-weight: 300;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      content: '+';
    }
  .procedureTable__order:hover .procedureTable__order_square {
      color: #ffffff;
      background: #ED4D74;
      -webkit-box-shadow: 4px 10px 35px rgba(239, 106, 160, 0.7);
              box-shadow: 4px 10px 35px rgba(239, 106, 160, 0.7);
    }
