.servicesPrice__list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 0;
    grid-row-gap: 60px;
  }
    .servicesPrice__list a {
      margin-top: 0;
    }
    @media(max-width: 996px) {.servicesPrice__list {
      grid-column-gap: 60px
  }
    }
    @media(max-width: 570px) {.servicesPrice__list {
      grid-row-gap: 30px;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
  }
    }