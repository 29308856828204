.instagram__list {
    width: calc(100% - 4px);
    padding: 1px;
    height: 700px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    margin: 0 0 20px;
    position: relative;
    overflow: hidden;
  }
    @media(min-width: 1440px) {.instagram__list {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-column-gap: 15px
  }
    }
    @media(max-width: 769px) {.instagram__list {
      height: 600px;
      grid-template-columns: repeat(auto-fill, minmax(268px, 1fr))
  }
    }
    .instagram__list:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 240px;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.00)), color-stop(66%, rgba(255,255,255,0.84)), to(#ffffff));
      background-image: linear-gradient(-180deg, rgba(255,255,255,0.00) 0%, rgba(255,255,255,0.84) 66%, #ffffff 100%);
    }