.line {
  width: 100%;
  background: #DDDFE0;
  height: 1px;

}

  .line__sale {
    width: calc(100% + 48px);
    margin-left: -24px;
  }

  .line.line--black {
    background: #272729;
  }

  .line.line--greyLight {
    background: #F0F0F2;
  }

  .line.line__margined--tb {
    margin: 10px 0;
  }

  .line.line__margined--t2b1 {
    margin: 25px 0 15px 0;
  }
