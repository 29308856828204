.rooms__section {
    position: relative;
    margin: 40px 0;
  }
    .rooms__section:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 280px;
      left: 0;
      bottom: -40px;
      background: #F5F5F7;
      z-index: -1;
    }
  .rooms__subHeading {
    max-width: 565px;
  }