.blog {
  margin-top: 40px;
}
  .blog__list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  @media(max-width: 570px) {
  .blog__list {
      grid-template-columns: minmax(290px, 1fr)
  }
    }