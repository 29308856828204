.vouchers__list {
    width: 100%;
    display: grid;
    margin: 2em 0;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
    
    @media(max-width: 769px) {.vouchers__list {
      grid-template-columns: repeat(auto-fill, minmax(282px, 1fr))
  }
    }