.contacts__map {
    margin-top: 0;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    outline: none;
  }
    .contacts__map iframe {
      border-radius: 8px;
    }
  .contacts__bg {
    background: #E8E8E8;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 400px;
  }