.sale__siblings_heading {
    color: #272729;
  }
    @media(max-width: 769px) {.sale__siblings_heading {
      margin: 30px 0 10px
  }
    }
  .sale__siblings .saleCard {
    -webkit-box-shadow: 0 3px 10px 0 rgb(107 111 130 / 18%);
            box-shadow: 0 3px 10px 0 rgb(107 111 130 / 18%);
    margin: 1em 0;
  }
  