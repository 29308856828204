.reviews__youtube {
    overflow: hidden;
    position: relative;
  }
  .reviews__cards {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding-bottom: 30px;
  }