.rest__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
    @media(max-width: 1440px) {.rest__grid {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
  }
    }

  @media(max-width: 996px) {

  .rest__gallery img {
        width: 100%
    }
      }

  .rest__column {
    width: 68%;
  }

  @media(max-width: 1440px) {

  .rest__column {
      width: 100%
  }
    }

  .rest__siblings {
    width: 28%;
    margin-top: -10px;
  }

  @media(max-width: 1440px) {

  .rest__siblings {
      width: 100%;
      margin-top: 20px
  }
    }

  .rest__siblings_list {
    margin-top: 20px;
  }

  @media(max-width: 1440px) {

  .rest__siblings_list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
      grid-column-gap: 24px;
      grid-row-gap: 24px
  }
    }