@font-face { font-family: H57e83; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAP4AAsAAAAABigAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPAAAAFYpoTZMY21hcAAAAYAAAABKAAABcOEoo6pnbHlmAAABzAAAAGkAAACASDRnC2hlYWQAAAI4AAAAMAAAADYnIU1xaGhlYQAAAmgAAAAZAAAAJABkAD9obXR4AAAChAAAAAgAAAAIADwAAGxvY2EAAAKMAAAABgAAAAYAQAAAbWF4cAAAApQAAAAeAAAAIAEOADxuYW1lAAACtAAAASkAAAIWm5e+CnBvc3QAAAPgAAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGSQY5zAwMrAwKDGoAwk2aG0BAMTAw8DkGBlZsAKAtJcUxgcHjA8YGAwAnJZgCRIJSNIDgDDHgVKeJztkLENgDAQA8/KQ4EYgSo1s1Cxv9gk+RjYIpbOkk9fPbAAJTmTAN2IkSut7AubfVB9E8M/tPZ37nCjlZndfXzLv3pBHRomCVEAAHicPY3BDkAwEER3S6tawgG9V1IHB6rVi///LGGRyCQzb+YywACuEzxpggXArMI6v4/WxXTgnzGFNHRixj5sb+4EKbrCMIYZPvY5YlNrlaunqFzKUhNJZMhpMMRlKznVseeVoO8b1CwK6AAAAHicY2BkYGAAYslSz+Z4fpuvDNwMRkARhscvOrgQ9P+/DIZAyMDAwcAEEgUAH7kKW3icY2BkYGAwYgABGzBpyMDIgAqYAA/nAKQAAAAAAAAAADwAAAAAAAAAQAAAeJxjYGRgYGBiMABiBjCLgYELCBkY/oP5DAANXgFLAAB4nGWQPW7CQBSEx2BIAlKCFCkps1UKIpmfkgNAT0GXwpi1MbK91npBossJcoQcIaeIcoIcKGPzaGAtP38zb97uygAG+IWHenm4bWq9WrihOnGb9CDsk5+FO+jjRbhLfyjcwxumwn084p07eP4dnQFK4Rbu8SHcpv8p7JO/hDt4wrdwl/6PcA8r/An38eoN08gUsSncUif7LLRnef6utK1SU6hJMD5bC11oGzq9Ueujqg7J1LlYxdbkas6uzjKjSmt2OnLB1rlyNhrF4geRyZEigkGBuKkOS2gk2CNDCHvVvdQrpi0q+rVWmCDA+Cq1YKpokiGVxobJNY6sFQ48bUrXMa34Ws7kpLnMat4kIyv+77q3oxPRD7BtpkrMMOITX+SD5g75Pz0RXqgAAAB4nGNgYsAPmBiZGJkZmDINGBgAAw8ApwA=) format('woff');font-weight: normal;font-style: normal;}
  .saleCard {
  -webkit-transition: -webkit-box-shadow ease-out .3s;
  transition: -webkit-box-shadow ease-out .3s;
  transition: box-shadow ease-out .3s;
  transition: box-shadow ease-out .3s, -webkit-box-shadow ease-out .3s;
}
  .saleCard:hover .card__title {
      color: #D8AB5E;
    }
  .saleCard .card__readMore { 
    margin-top: 1em;
  }
  .saleCard .card__footer {
    min-height: 5em;
  }
  .saleCard__label {
    line-height: 30px;
    font-family: Alegreya;
    font-size: 34px;
    font-weight: bold;
    color: #e65858;
  }
  .saleCard__description {
    margin-top: .4em;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 300;
    line-height: 21px;
    max-width: 95%;
    height: 43px;
    overflow: hidden;
    display: block;
  }
  .saleCard__price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -ms-flex-item-align: end;
        align-self: flex-end;
    width: 100%;
    font-size: 14px;
  }
  .saleCard__price > span {
      font-size: 1.3em;
      font-weight: 600;
      font-family: 'Roboto';
      line-height: 1em;
    }
  .saleCard:hover .saleCard__price > span {
        color: #e65858;
      }
  .saleCard__price_at {
      font-size: .7em;
      margin-right: .3em;
    }
  .saleCard__currentPrice {
    display: inline;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: bold;
    line-height: 1em;
    position: relative;
  }
  .saleCard:hover .saleCard__currentPrice {
      color: #e65858;
    }
  .saleCard__currentPrice:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: H57e83;
      font-weight: normal;
      content: '\E000';
      font-size: 14px;
      font-weight: 700;
    }
  .saleCard__currentPrice span {
      font-size: 16px;
    }
  .saleCard__oldPrice {
    display: inline;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2em;
    text-decoration: line-through;
    color: #78787A;
    margin-right: 15px;
  }
  .saleCard__oldPrice:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: H57e83;
      font-weight: normal;
      content: '\E000';
      font-size: 10px;
      font-weight: 300;
    }
  .saleCard__wrapper { 
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    height: 100%;
  }
  .saleCard__offer { 
    color: #78787A;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.2em;
  }

.saleCard--big {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.4em;
  max-width: 760px;
  width: 100%;
  -webkit-transition: -webkit-box-shadow ease-out .3s;
  transition: -webkit-box-shadow ease-out .3s;
  transition: box-shadow ease-out .3s;
  transition: box-shadow ease-out .3s, -webkit-box-shadow ease-out .3s;
}

@media(max-width: 570px) {

.saleCard--big {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column
}
  }

.saleCard--big .card__footer {
    padding: 1em 0 0;
  }

@media(min-width: 571px){

.saleCard--big .card__footer {
      padding: 0
  }
      .saleCard--big .card__footer:before {
        display: none;
      }
    }

.saleCard--big .card__content {
    height: 100%;
  }

.saleCard--big .card__content .card__footer:before {
      display: none;
    }

.saleCard--big .saleCard__description {
    height: auto;
    max-height: 186px;
    margin-top: .7em;
    max-width: 100%;
  }

.saleCard--big .saleCard__price {
    font-size: 19px;
  }

.saleCard--big .saleCard__label {
    position: relative;
    left: 0;
    font-size: 60px;
    line-height: 1em;
    font-weight: 500;
  }

.saleCard__ribbon {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    padding: 42px 24px;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

@media(max-width: 570px) {

.saleCard__ribbon {
      display: none
  }
    }

.saleCard__ribbon__line {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: #F0F0F2; stroke-width: 4; stroke-dasharray: 6 20'/></svg>");
      width: 2px;
      height: 100%;
    }

.saleCard__ribbon:before, .saleCard__ribbon:after {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 9999px;
      left: -11px;
      z-index: 2;
 
    }

.saleCard__ribbon:before {
      background: #566E9E;
      top: -12px;
    }

.saleCard__ribbon:after {
      background: #566E9E;
      bottom: -12px;
      -webkit-transition: all ease-out .3s;
      transition: all ease-out .3s;
    }

@media(max-width: 570px) {

.saleCard__ribbon:after {
        right: -11px;
        bottom: none;
        top: -12px
    }
      }
