.voucher__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    width: 100%;
  }
  .voucher__column {
    width: 28%;
    margin-right: 2%;
  }
  @media(max-width: 996px) {
  .voucher__column {
      width: 100%
  }
    }
  .voucher__column:last-child {
      margin: 0;
      width: 70%;
    }
  @media(max-width: 996px) {
  .voucher__column:last-child {
        width: 100%
    }
      }
  .voucher__section {
    margin-top: 40px;
  }