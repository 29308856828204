.requisites__items {
    margin-top: 10px;
  }
  .requisites__item {
    font-size: 16px;
    font-family: Roboto;
    font-weight: 300;
  }
  .requisites__item > b {
      font-weight: 500;
      font-size: 14px;
    }
  .requisites__director {
    margin-top: 20px;
  }

.requisites__ul {
  margin-bottom: 50px;
}