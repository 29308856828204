.consultationDoctor {
  position: relative;
  overflow: hidden;
  height: 247px;
}

  .consultationDoctor__mail {
    bottom: 0;
    position: absolute;
    margin: 20px 0 20px 0;
    color: #ffffff;
    display: inline-block;
    font-size: 15px;
    text-decoration: none;
    font-weight: 300;
    background: linear-gradient(162.86deg, #de62a09e 0%, #f2839fad 100%);
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    max-width: 180px;
    text-shadow: none;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }

  .consultationDoctor__mail:hover {
      background: linear-gradient(162.86deg, #DE62A0 0%, #F2839F 100%);
    }

  .consultationDoctor__content {
    position: relative;
    padding: 24px;
    z-index: 2;
    height: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box
  }

  .consultationDoctor__image {
    z-index: 1;
    position: absolute;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .consultationDoctor__image:before {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(290deg, #3e6ecc 30%, #3e84cc 100%);;
      opacity: .3;
    }

  .consultationDoctor__image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }