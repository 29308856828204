.rooms__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 2%;
  grid-row-gap: 24px;
  grid-row: 2;
}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.rooms__list {
    display: block;
    width: 100%
}
    .rooms__list > a {
      width: calc(33.3% - 20px);
      margin-bottom: 30px;
    }
    .rooms__list > a:nth-child(1n) {
      float: left;
      margin-right: 30px;
      clear: none;
    }
    .rooms__list > a:last-child {
      margin-right: 0;
    }
    .rooms__list > a:nth-child(3n) {
      margin-right: 0;
      float: right;
    }
    .rooms__list > a:nth-child(3n + 1) {
      clear: both;
    }
  }
  @media(max-width: 996px) {.rooms__list {
    grid-template-columns: repeat(2, 1fr)
}
  }
  @media(max-width: 570px) {.rooms__list {
    grid-template-columns: repeat(1, 1fr)
}
  }