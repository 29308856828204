.restWelcome {
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
  .restWelcome__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 330px;
    width: 100%;
    background: #566E9E;
  }
  .restWelcome__bg > img {
      position: absolute;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  .restWelcome__bg:after {
      content: '';
      background-image: url('/images/patern--right.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 100%;
      right: 0;
      top: 0;
      display: block;
    }
  @media(max-width: 570px) {
  .restWelcome__bg:after {
        display: none
    }
      }
  .restWelcome__bg:before {
      content: '';
      background-image: url('/images/patern--left.svg');
      background-size: cover;
      position: absolute;
      width: 235px;
      background-repeat: no-repeat;
      height: 242px;
      left: 0;
      top: 0;
      display: block;
    }
  .restWelcome__content {
    width: 100%;
    margin-top: 100px;
    z-index: 1;
  }
  @media(max-width: 769px) {
  .restWelcome__content {
      margin-top: 50px
  }
    }
  @media(max-width: 769px) {
  .restWelcome__content .heading.restWelcome__heading {
        font-size: 35px;
        line-height: 40px
    }
      }