.footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    background: #F5F5F7;
    width: 100%;
    margin-top: -1px;
    padding: 60px 0;
}
    @media(max-width: 570px) {.footer {
        padding: 30px 0
}
    }
    .footer__information {
        font-size: 12px;
        color: #78787A;
        font-weight: 300;
        margin-top: 20px;
    }
    .footer__information > a {
            margin-right: 10px;
            font-size: 12px;
            font-weight: 300;
            text-decoration: none;
            color: #78787A;
            margin-bottom: 16px;
            -webkit-transition: all .2s ease;
            transition: all .2s ease;
        }
    .footer__information > a:hover {
                color: #2F7DE1;
            }
    .footer__information > a:last-child {
                margin-bottom: 0;
            }
    .footer__author {
        text-decoration: none;
        color: #78787A;
        font-weight: 500;
    }
    .footer__columns {
        width: 100%;
        display: grid;
        grid-template-columns: 240px 240px 210px 1fr 1fr;
        grid-auto-rows: auto;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        grid-row: 2;
        margin-bottom: 60px;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .footer__columns {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between
    }
        }
    @media(max-width: 1440px) {
    .footer__columns {
            grid-template-columns: repeat(5, minmax(190px, 1fr));
            grid-row-gap: 54px;
            grid-column-gap: 0
    }
        }
    @media(max-width: 1279px) {
    .footer__columns {
            grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
            grid-row-gap: 34px;
            grid-column-gap: 24px
    }
        }
    @media(max-width: 570px) {
    .footer__columns {
            grid-row-gap: 15px;
            margin-bottom: 40px
    }
        }
    @media(max-width: 769px) {
    .footer__column.footer__column--nomobile {
                display: none
        }
            }
    @media(max-width: 769px) {
    .footer__column.footer__column--mobile {
                display: block
        }
            }
    .footer__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        position: relative;
    }
    .footer__item > a {
            font-size: 14px;
            font-weight: 300;
            text-decoration: none;
            color: #272729;
            margin-bottom: 20px;
            -webkit-transition: all .2s ease;
            transition: all .2s ease;
        }
    .footer__item > a:hover {
                color: #D8AB5E;
            }
    .footer__item > a:last-child {
                margin-bottom: 0;
            }
    .footer__item--row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    .footer__payment {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .footer__payment > img {
            width: 44px;
            height: 25px;
            margin-right: 15px;
        }
    .footer__title {
        font-weight: 900;
        font-size: 16px;
        font-family: 'Noah';
        margin-bottom: 24px;
    }
    .footer__title_address {
            margin-bottom: 10px;
        }
    .footer__copyright {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        font-size: 12px;
        color: #78787A;
        font-weight: 300;
        margin-top: 20px;
    }
    .footer__copyrightLink {
        color: #78787A;
        text-decoration: none;
        margin-left: 6px;
    }
    @media(max-width: 570px) {
    .footer__copyrightLink {
            margin-bottom: 5px
    }
        }
    .footer__copyrightLink:hover {
            color: #D8AB5E;
        }
    .footer__copyrightLink:last-child {
            margin-right: 0;
        }
    .footer__social {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        height: 170px;
    }

.footer__items + .footer__items {
    margin-top: 30px;
}