@font-face { font-family: Ma2026; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAPUAAsAAAAABegAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqRzbGY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAAEAAAABAs9NHd2hlYWQAAAIQAAAALgAAADYnVE3GaGhlYQAAAkAAAAAbAAAAJADLAGZobXR4AAACXAAAAAgAAAAIAGQAAGxvY2EAAAJkAAAABgAAAAYAIAAAbWF4cAAAAmwAAAAeAAAAIAENAB5uYW1lAAACjAAAASkAAAIWm5e+CnBvc3QAAAO4AAAAGwAAACxqOAEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGQwYpzAwMrAwGDP4AYk+aC0AQMLgyQDAxMDKzMDVhCQ5prC4PCA4QEDQwqQywkmGRgYQQQA+uoGagAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAAAAQAAAAAAZQBPABEAADcUDwEGIi8BJjQ2Mh8BNzYyFmQCKgMGAisCBQYCJSUCBwRHAwMqAgIqAwYFAiUlAgUAeJxjYGRgYABi++Udv+P5bb4ycDOkAEUYHr/o4EamGVIZ/IEkBwMTiAMAOoIJ9AAAeJxjYGRgYEhhYICQ//8zpDIwMqACJgBIjgMwAAAAAAAAZAAAAAAAAAAgAAB4nGNgZGBgYGIQYgDRIBYDAxcQMjD8B/MZAApZASwAAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BigABGBuyAiZGJkZmBKdOAgQEAAzIAqAA=) format('woff');font-weight: normal;font-style: normal;}
  
  .priceAccordion {
  background: #ffffff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  max-width: 100%;
  border: 1px solid #F0F0F2;
}
  
  .priceAccordion__header { 
    padding: 1em 2em;
    color: #78787A;
    font-weight: 300;
    font-size: .9em;
  }
  
  .priceAccordion__title {
    padding: 0 2em;
    font-weight: 500;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow: hidden;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .priceAccordion__title_text {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  
  .priceAccordion__title_price {
      width: 120px;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 120px;
              flex: 0 0 120px;
    }
  
  @media(max-width: 570px){
  
  .priceAccordion__title_price {
        width: 80px;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 80px;
                flex: 0 0 80px
    }
      }
  
  .priceAccordion__title:after {
      display: block;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: Ma2026;
      font-weight: normal;
      content: '\E000';
      font-size: .8em;
      color: #78787A;
      -webkit-transition: all ease-out .2s;
      transition: all ease-out .2s;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 20px;
              flex: 0 0 20px;
    }
  
  .priceAccordion__title:hover {
      color: #D8AB5E;
    }
  
  .priceAccordion__list {
    padding: 0 0 .2em 0;
    background: #ffffff;

  }
  
  .priceAccordion__item {
    padding: 1em 0;
    -webkit-transition: all ease .2s;
    transition: all ease .2s;
    border-top: 1px solid #F0F0F2;
    overflow: hidden;
    height: 16px;
  }
  
  .priceAccordion__item--active {
      background: #F5F5F7;
      height: auto;   
    }
  
  .priceAccordion__item--active .priceAccordion__title {
        cursor: default;
      }
  
  .priceAccordion__item--active .priceAccordion__title_price {
          display: none;
        }
  
  .priceAccordion__item--active .priceAccordion__title:after {
          -webkit-transform: rotateX(180deg);
                  transform: rotateX(180deg);
        }
  
  .priceAccordion__item--active .priceAccordion__title:hover {
          color: #272729;
        }