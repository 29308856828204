.sale__description {
    font-size: 18px;
    margin-right: 20px;
  }

  .sale__article {
    margin-top: 30px;
  }

  .sale__cover {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 350px;
    margin-bottom: 20px;
  }

  .sale__time {
    color: #272729;
    font-weight: 300;
    font-size: 18px;
  }

  .sale__price {
    font-size: 40px;
    font-family: 'Noah';
    font-weight: 500;
  }

  .sale__price > span {
      margin-right: 15px;
      font-size: 24px;
      font-weight: 400;
      text-decoration: line-through;
      color: #DDDFE0;
    }

  .sale__sale {
    padding: 42px;
    margin-top: 50px;
    margin-bottom: -50px;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: #ffffff;
    -webkit-box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
            box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
    position: relative;
  }

  .sale__sale:before {
      content: '';
      height: 1px;
      width: calc(100% - 84px);
      display: block;
      position: absolute;
      top: 115px;
      background: #DDDFE0;
    }

  @media(max-width: 769px) {

  .sale__sale:before {
        width: calc(100% - 30px)
    }
      }

  @media(max-width: 769px) {

  .sale__sale {
      padding: 15px
  }
    }