.reviews__instagramList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 2%;
    grid-row-gap: 24px;
    grid-row: 2;
  }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.reviews__instagramList {
      display: block;
      width: 100%
  }
      .reviews__instagramList > div {
        width: calc(24.975% - 22.5px);
        margin-bottom: 30px;
      }
      .reviews__instagramList > div:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .reviews__instagramList > div:last-child {
        margin-right: 0;
      }
      .reviews__instagramList > div:nth-child(4n) {
        margin-right: 0;
        float: right;
      }
      .reviews__instagramList > div:nth-child(4n + 1) {
        clear: both;
      }
    }
    @media(max-width: 996px) {.reviews__instagramList {
      grid-template-columns: repeat(auto-fill, minmax(45%, 1fr))
  }
    }
    @media(max-width: 769px) {.reviews__instagramList {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
  }
    }