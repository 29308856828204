.layout__wrapper, .layout__main {
    min-height: calc(100% - 44px);
  }
  
@media(min-width: 997px) {
  #Root{
    height: 100vh;
    width: 100%;
  }

  .layout__wrapper {
    height: calc(100% - 44px);
    min-height: none;
    width: 100%;
    position: relative;
    display: inline-table;
  }

  .layout__main {
    display: table-row;
    height: 100%;
    min-height: none;
  }

  .layout__footer {
    display: table-row;
    height: 1px;
  }
}