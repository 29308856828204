.rules__content {
  font-size: 16px;
  color: #272729;

}
  .rules__content p, .rules__content ol, .rules__content ul {
    margin: 1.2em 0;
    font-weight: 400;
    line-height: 1.4em;
  }
  .rules__content ul, .rules__content ol {
    -webkit-padding-start: 1em;
            padding-inline-start: 1em;

  }
  .rules__content ol + ul {
    -webkit-padding-start: 3em;
            padding-inline-start: 3em;
  }
  .rules__content li {
    font-weight: 400;
    margin: 0;
  }