.rest__list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
  @media(max-width: 769px) {.rest__list {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr))
}
  }

.rest__siblings_list > .excursionCard {
  margin: 1em 0;
}