.methodSiblings__list {
    display: grid;
    width: 100%;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    margin: 1em 0;
  }
    @media(max-width: 996px) {.methodSiblings__list {
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr))
  }
    }
    @media (max-width: 769px) {.methodSiblings__list {
      grid-template-columns: repeat(auto-fill, minmax(45%, 1fr))
  }
    }
    @media (max-width: 570px) {.methodSiblings__list {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
  }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.methodSiblings__list {
      display: block;
      width: 100%
  }
      .methodSiblings__list > a {
        width: calc(16.65% - 25px);
        margin-bottom: 30px;
      }
      .methodSiblings__list > a:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .methodSiblings__list > a:last-child {
        margin-right: 0;
      }
      .methodSiblings__list > a:nth-child(6n) {
        margin-right: 0;
        float: right;
      }
      .methodSiblings__list > a:nth-child(6n + 1) {
        clear: both;
      }
    }
  .methodSiblings__title {
    font-size: 2em;
    font-weight: 900;
    font-family: 'Noah';
  }
  @media(min-width: 997px) {
  .methodSiblings__title {
      color: #ffffff;
      font-size: 2.5em
  }
    }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .methodSiblings__title {
      color: #272729
  }
    }