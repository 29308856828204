.vouchers_slide__pagination.swiper-pagination {
  position: absolute;
  bottom: 4px !important;
}
.vouchers__slider {
  width: 100%;
}
@media(min-width: 1540px) {
.vouchers__slider {
    overflow: hidden
}
  }
.vouchers__slider .voucherCardShort {
    margin-bottom: 2em;
  }
.vouchers__slider {
  padding: 2em 1em 1.5em; 
  margin: -2em -1em 0em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}
@media(min-width: 571px) {
    .vouchers__slider .vouchers__slider {
      padding: 2em 1em 1em; 
      margin: -2em -1em 0em;
    }
  }