.mainWelcome {
  margin: 0 0 60px;
}
  @media(max-width: 996px) {.mainWelcome {
    margin: 0
}
  }
  .mainWelcome__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    overflow: hidden;
  }
  .mainWelcome__background:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #272729;
      opacity: 0.45;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      z-index: 1;
    }
  @media(max-width: 1279px) {
  .mainWelcome__background {
      border-radius: 0
  }
    }
  .mainWelcome__img {
    width: 100%;
    max-height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 49%;
       object-position: 49%;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mainWelcome__img {
      max-height: none;
      -o-object-position: center;
         object-position: center
  }
    }
  @media(max-width: 769px) {
  .mainWelcome__img {
      height: 100%
  }
    }
  .mainWelcome__btn {
    padding: 7px 28px;
    margin-bottom: 20px;
  }
  @media(min-width: 571px) {
  .mainWelcome__btn {
      padding: 12px 39px
  }
    }
  .mainWelcome__button {
    margin: 20px 0 0;
    padding: 4px 23px;
  }
  @media(min-width: 571px) {
  .mainWelcome__button {
      display: none
  }
    }
  .mainWelcome__video {
    position: absolute;
    top: -100px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 8px;
    z-index: 1;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;
  }
  .mainWelcome__video .youtubeVideo {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: opacity 1s ease 4s;
      transition: opacity 1s ease 4s;
      opacity: 0;
    }
  .mainWelcome__video .youtubeVideo.youtubeVideo--active {
        opacity: 1;
      }
  @media(max-width: 1279px) {
  .mainWelcome__video {
      display: none
  }
    }
  .mainWelcome__coverVideo {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @media(max-width: 1279px) {
  .mainWelcome__coverVideo {
      display: none
  }
    }
  .mainWelcome__wrapper {
    position: relative;
    margin: 85px 0 60px;
    z-index: 2;
    color: #ffffff;
    width: 100%;
  }
  @media(min-width: 1280px) {
  .mainWelcome__wrapper {
      margin: 140px 78px
  }
    }
  @media(min-width: 1540px) {
  .mainWelcome__wrapper {
      margin: 170px 90px
  }
    }
  .mainWelcome__title {
    color: #ffffff;
    font-size: 56px;
    line-height: 64px;
    font-weight: 900;
    font-family: 'Noah';
    max-width: 820px;
    margin: 25px 0 13px;
  }
  @media(max-width: 996px) {
  .mainWelcome__title {
      font-size: 45px;
      line-height: 55px
  }
    }
  @media(max-width: 769px) {
  .mainWelcome__title {
      font-size: 35px;
      line-height: 45px
  }
    }
  @media(max-width: 570px) {
  .mainWelcome__title {
      font-size: 37px;
      line-height: 42px
  }
    }
  .mainWelcome__description {
    font-family: 'Roboto';
    font-weight: 400;
    margin-bottom: 90px;
    max-width: 200px;
    font-size: 16px;
    line-height: 24px;
  }
  @media(min-width: 571px) {
  .mainWelcome__description {
      max-width: 820px;
      margin-bottom: 30px
  }
    }
  @media(min-width: 769px) {
  .mainWelcome__description {
      line-height: 32px;
      font-size: 18px
  }
    }