.rest__list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
  @media(max-width: 769px) {.rest__list {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr))
}
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.rest__list {
    display: block;
    width: 100%
}
    .rest__list > a {
      width: calc(33.3% - 20px);
      margin-bottom: 30px;
    }
    .rest__list > a:nth-child(1n) {
      float: left;
      margin-right: 30px;
      clear: none;
    }
    .rest__list > a:last-child {
      margin-right: 0;
    }
    .rest__list > a:nth-child(3n) {
      margin-right: 0;
      float: right;
    }
    .rest__list > a:nth-child(3n + 1) {
      clear: both;
    }
  }