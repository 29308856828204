.menuFooter__grid {
    display: grid;
    width: 100%;
    grid-template-columns: 30% 70%;
    grid-auto-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    position: relative;
  }
    .menuFooter__grid:before {
      content: '';
      position: absolute;
      top: -24px;
      left: 0;
      background: #F0F0F2;
      height: 1px;
      width: 100%;
    }
    @media(max-width: 996px) {.menuFooter__grid {
      grid-template-columns: 1fr;
      grid-row-gap: 40px
  }
    }
  .menuFooter__items {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  @media(max-width: 769px) {
  .menuFooter__items {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
  }
    }
  .menuFooter__item a {
      width: 100%;
      display: block;
      color: #272729;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 12px;
      text-decoration: none;
      -webkit-transition: .3s ease-out;
      transition: .3s ease-out;
    }
  .menuFooter__item a:hover {
        color: #D8AB5E;
      }