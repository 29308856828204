.post__article{
    width: 100%;
    margin-top: 2em;
  }
  .post__date {
    color: #272729;
    font-weight: 400;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    margin: 0 0 20px;
  }