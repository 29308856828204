.contacts__welcome {
    margin: 40px 0 20px;
  }
  .contacts__items {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .contacts__phone .phone {
    color: #D8AB5E;
  }
  .contacts__item {
    color: #D8AB5E;
    font-size: 24px;
    font-family: Noah;
    margin-top: -2px;
    font-weight: 900;
    margin: 0 60px 40px 0;
  }
  .contacts__item:last-child {
      margin-right: 0;
    }
  .contacts__item--small {
    color: #D8AB5E;
    font-size: 16px;
    font-family: Noah;
    margin-top: -2px;
    font-weight: 900;
    margin: 0 40px 40px 0;
  }
  .contacts__item--small:last-child {
      margin-right: 0;
    }
  .contacts__itemTitle {
    font-family: 'Roboto';
    font-weight: 500;
    color: #272729;
    margin-bottom: 6px;
    font-size: 16px;
  }
  .contacts__itemTitle--small {
    font-family: 'Roboto';
    font-weight: 500;
    color: #272729;
    margin-bottom: 6px;
    font-size: 14px;
  }
  .contacts__grid {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-column-gap: 100px;
       -moz-column-gap: 100px;
            column-gap: 100px;
    row-gap: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .contacts__grid_item {  
    display: -webkit-box;  
    display: -ms-flexbox;  
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    max-width: 450px;
  }
  .contacts__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: -10px 0 10px 0;
  }
  .contacts__button {
    margin-right: 30px;
    border-radius: 8px;
    padding: 2px 28px;
    color: #D8AB5E;
  }
  .contacts:last-child {
    margin-right: 0;
  }