@font-face { font-family: z0909c; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAQkAAsAAAAABkgAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqRzbGY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAAIoAAACgsQaf3WhlYWQAAAJcAAAAMQAAADYnU03MaGhlYQAAApAAAAAbAAAAJADKAGZobXR4AAACrAAAAAgAAAAIAGT//2xvY2EAAAK0AAAABgAAAAYAUAAAbWF4cAAAArwAAAAeAAAAIAEOAEduYW1lAAAC3AAAASkAAAIWm5e+CnBvc3QAAAQIAAAAGwAAACxqOAEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGQwYpzAwMrAwGDP4AYk+aC0AQMLgyQDAxMDKzMDVhCQ5prC4PCA4QEDQwqQywkmGRgYQQQA+uoGagAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAB4nBXOMQqDQBQE0D+z+sVtBFlNkyCouIXFgohCRHKCHCFl7n8E89POvIERXpeIfOUjgzxF9qFCE3RGP51Yt4Mn49DrA6E9sGwn4j5ZqaFDhY4v3Np/ur4zIHcpy/MskrEoyyKpRwPeSfgRUHD0cJyB2qm65BQ28mrOa40FdAZpEAZpQzv2A3z3DSoAAHicY2BkYGAAYpcHVy7H89t8ZeBmSAGKMDx+0cEOo///BzJSGWKBJAcDE0gUAG6EDFoAAAB4nGNgZGBgSGEAgZT/QMCQysDIgAqYAHdfBS4AAAAAAABk//8AAAAAAFAAAHicY2BkYGBgYrAGYgYwi4GBCwgZGP6D+QwADnEBVgAAeJxlkD1uwkAUhMdgSAJSghQpKbNVCiKZn5IDQE9Bl8KYtTGyvdZ6QaLLCXKEHCGniHKCHChj82hgLT9/M2/e7soABviFh3p5uG1qvVq4oTpxm/Qg7JOfhTvo40W4S38o3MMbpsJ9POKdO3j+HZ0BSuEW7vEh3Kb/KeyTv4Q7eMK3cJf+j3APK/wJ9/HqDdPIFLEp3FIn+yy0Z3n+rrStUlOoSTA+WwtdaBs6vVHro6oOydS5WMXW5GrOrs4yo0prdjpywda5cjYaxeIHkcmRIoJBgbipDktoJNgjQwh71b3UK6YtKvq1VpggwPgqtWCqaJIhlcaGyTWOrBUOPG1K1zGt+FrO5KS5zGreJCMr/u+6t6MT0Q+wbaZKzDDiE1/kg+YO+T89EV6oAAAAeJxjYGKAAEYG7ICJkYmRmYEp04CBAQADMgCoAA==) format('woff');font-weight: normal;font-style: normal;}
  .searchCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}
  .searchCard__logo {
    width: 200px;
    position: relative;
  }
  .searchCard__logo > img {
      -o-object-fit: contain;
         object-fit: contain;
      width: 100%;
      height: 44px;
    }
  .searchCard__title {
    font-size: 20px;
    font-weight: 400;
  }
  .searchCard__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .searchCard__button {
    padding: 9px 30px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #3d7ab6;;
    background: #ecf1f7;
    cursor: pointer;
    border: none;
    outline: none;
    text-decoration: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 400;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
  }
  @media(max-width: 570px) {
  .searchCard__button {
      margin: 10px 0
  }
    }
  .searchCard__button:hover {
      background: #e4ebf3;
    }
  .searchCard__button:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: z0909c;
      font-weight: normal;
      content: '\E000';
      position: relative;
      margin-left: 7px;
    }
  .searchCard__list {
    margin: 25px 0 20px;
    position: relative;
    height: 410px;
    overflow: hidden;
  }
  .searchCard__list:after {
      content: '';
      background: -webkit-gradient(linear, left top, left bottom, from(#ffffff00), color-stop(#F5F5F7b8), to(#F5F5F7));
      background: linear-gradient(to bottom, #ffffff00, #F5F5F7b8, #F5F5F7);
      width: 100%;
      height: 100px;
      position: absolute;
      bottom: 0;
      display: block;
    }
  @media(max-width: 996px) {
  .searchCard__list {
      height: 380px
  }
      .searchCard__list:after {
        height: 50px;
      }
    }
  .searchCard__average {
    line-height: 30px;
    color: #B8BCC8;
    margin-left: 8px;
  }
  .searchCard__moreContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .searchCard__moreLink {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #3d7ab6;
    border-radius: 50px;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
    margin: 0 0 20px;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 7px 33px;
    text-decoration: none;
  }
  .searchCard__moreLink.searchCard__moreLink--absolute {
      position: absolute;
      border: none;
      padding: 0;
      right: 24px;
      color: #DDDFE0;
      top: 24px;
      margin: 0;
    }
  @media(max-width: 769px) {
  .searchCard__moreLink.searchCard__moreLink--absolute {
        display: none
    }
      }
  .searchCard__moreLink:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: z0909c;
      font-weight: normal;
      content: '\E000';
      position: relative;
      margin-left: 5px;
    }