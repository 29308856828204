.sales {
  margin: 0;
  background: #F5F5F7;
}
  .sales__list {
    position: relative;
    top: -80px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  @media(max-width: 769px) {
  .sales__list {
      grid-template-columns: repeat(auto-fill, minmax(282px, 1fr))
  }
    }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sales__list {
      display: block;
      width: 100%
  }
      .sales__list > a {
        width: calc(33.3% - 20px);
        margin-bottom: 30px;
      }
      .sales__list > a:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .sales__list > a:last-child {
        margin-right: 0;
      }
      .sales__list > a:nth-child(3n) {
        margin-right: 0;
        float: right;
      }
      .sales__list > a:nth-child(3n + 1) {
        clear: both;
      }
    }