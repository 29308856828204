.container{
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.container.container--nowrap {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
.container.container--relative {
    position: relative;
  }
@media(max-width: 1279px) {
.container.container--relative {
      position: initial
  }
    }
.container.container--row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
.container.container--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
.container.container--ai_center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
.container.container--jc_center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
.container.container--jc_between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
.container.container--jc_around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
.container.container--jc_start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
.container.container--jc_end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
@media (min-width: 320px) {
  .container {
    margin: 0 15px;
    width: calc(100% - 30px);
  }
}
@media (min-width: 571px) {
  .container {
    margin: 0 30px;
    width: calc(100% - 60px);
  }
}
@media (min-width: 769px) {
  .container  {
    margin: 0 40px;
    width: calc(100% - 80px);
  }
}
@media (min-width: 997px) {
  .container  {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    max-width: 970px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1280px) {
  .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

    .container.container--large {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      max-width: 1360px;
      margin-left: auto;
      margin-right: auto;
    }
}
@media(min-width: 1540px) {
  .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

    .container.container--large {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      max-width: 1560px;
      margin-left: auto;
      margin-right: auto;
    }
}