.sale__vcard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -ms-flex-direction: column;
        flex-direction: column;
    background: #ffffff;
    -webkit-box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
            box-shadow: 0 3px 10px 0 rgba(107,111,130,0.13);
    margin-bottom: 30px;
  }
    @media(max-width: 1279px) {.sale__vcard {
      display: none
  }
    }
  .sale__titleDate {
    font-family: 'Noah';
    font-weight: 700;
    font-size: 1.7em;
  }
  .sale__titleDate > p {
      font-family: Roboto;
      font-size: 18px;
      margin: 0px 0 14px;
    }
  .sale__price_vcard {
    margin: 16px 0;
  }
  .sale__price_at {
    margin-right: 5px;
  }
  .sale__date {
    margin-bottom: 30px;
    color: #ffffff;
    font-family: 'Noah';
    font-size: 28px;
    line-height: 1em;
    font-weight: 500;
  }
  @media(max-width: 1279px) {
  .sale__date {
      display: none
  }
    }
  .sale__date_title {
      color: #ffffff;
      font-size: 16px;
      line-height: 1em;
      font-weight: 400;
    }
  .sale__currentPrice {
    font-size: 30px;
    display: inline;
    font-family: 'Noah';
    font-weight: bold;
    line-height: 26px;
  }
  .sale__currentPrice .sale__currentPrice--unit {
      font-size: 22px;
      margin-left: 6px;
    }
  .sale__oldPrice {
    display: inline;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    text-decoration: line-through;
    margin-right: 15px;
    color: #DDDFE0;
  }