.mainReviews {
  padding: 70px 0 70px;
  background: linear-gradient(89.73deg, #F0F0F2 0.51%, #F0F0F2 17.86%, #F0F0F2 76.02%, #F0F0F2 99.78%);
}
  @media(max-width: 769px) {.mainReviews {
    padding: 50px 0 50px
}
  }
  .mainReviews__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  @media(max-width: 769px) {
  .mainReviews__grid {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
  }
    }
  .mainReviews__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mainReviews__right {
    cursor: pointer;
  }
  @media(max-width: 769px) {
  .mainReviews__right {
      margin-top: 40px
  }
    }
  .mainReviews__right img {
      width: 290px;
      height: 395px;
      -o-object-fit: cover;
         object-fit: cover;
      border-radius: 8px;
    }
  .mainReviews__number {
    position: absolute;
    font-size: 80px;
    line-height: 64px;
    color: #B7881E;
    font-family: 'Noah';
    font-weight: 900;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mainReviews__number {
      left: 19%;
      top: 40%
  }
    }
  .mainReviews__number span {
      font-size: 48px;
    }
  .mainReviews__number p {
      font-size: 42px;
      color: #272729;
      font-weight: 900;
      margin-top: -2px;
    }
  @media(max-width: 996px) {
  .mainReviews__number {
      display: none
  }
    }
  .mainReviews__cover {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  @media(max-width: 996px) {
  .mainReviews__cover {
      position: absolute
  }
    }
  .mainReviews__cover img {
      width: 325px;
      height: 300px;
      -o-object-fit: contain;
         object-fit: contain;
    }
  @media(max-width: 769px) {
  .mainReviews__cover img {
        display: none
    }
      }
  .mainReviews__title {
    font-family: 'Noah';
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    margin-left: 10px;
  }
  @media(max-width: 1279px) {
  .mainReviews__title {
      font-size: 48px;
      line-height: 54px;
      margin-left: 0
  }
    }
  @media(max-width: 996px) {
  .mainReviews__title {
      display: none
  }
    }
  .mainReviews__title--mobile {
      display: none;
      z-index: 1;
      margin-right: 20px;
    }
  @media(max-width: 996px) {
  .mainReviews__title--mobile {
        display: block
    }
      }
  .mainReviews__title span {
      font-weight: 700;
      display: block;
    }