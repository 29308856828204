.searchReviewCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}
  .searchReviewCard__avatar > img {
      width: 45px;
      -o-object-fit: cover;
         object-fit: cover;
      border-radius: 35px;
      -o-object-position: center;
         object-position: center;
      height: 45px;
    }
  .searchReviewCard__description {
    color: #424242;
  }
  .searchReviewCard__starsBlock {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
  }
  .searchReviewCard__date {
    color: #78787A;
    margin-left: 10px;
  }
  .searchReviewCard__content {
    margin-left: 12px;
  }
  .searchReviewCard__name {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 2px;
  }
  .searchReviewCard__starsBlock .ratingStars {
      width: auto;
    }
  .searchReviewCard__starsBlock .ratingStars > img {
        margin-right: 2px;
        width: 12px;
      }