.document {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px 0 0;
  z-index: 1;
  text-decoration: none;
  position: relative;
}
  .document > img {
    height: 52px;
  }
  .document__extra {
    color: #272729;
    margin-left: 14px;
  }
  .document__extra > div {
      -webkit-transition: color ease .2s;
      transition: color ease .2s;
    }
  .document__extra > span {
      color: #DDDFE0;
    }