.consultationQuestion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  padding: 2em;
  border-radius: 4px;
  margin-top: 30px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #f5f5f7;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
  .consultationQuestion__subHeading {
    margin-top: 4px;
  }
  @media(max-width: 996px) {.consultationQuestion {
    padding: 1.8em 2em
}
  }
  @media(max-width: 570px) {.consultationQuestion {
    width: calc(100% + 2px);
    left: -15px;
    position: relative;
    padding: 1.8em 1em;
    border-radius: 0
}
  }
  @media(max-width: 996px) {
  .consultationQuestion__button {
      margin-top: 10px;
      padding: 6px 25px
  }
    }