@font-face { font-family: Dcd6f5; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAQwAAsAAAAABmwAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYqRzbGY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAAJsAAADEvjIXT2hlYWQAAAJsAAAALgAAADYnVE3AaGhlYQAAApwAAAAbAAAAJADLAGZobXR4AAACuAAAAAgAAAAIAGQAAGxvY2EAAALAAAAABgAAAAYAYgAAbWF4cAAAAsgAAAAeAAAAIAENAE5uYW1lAAAC6AAAASkAAAIWm5e+CnBvc3QAAAQUAAAAGwAAACxqOAEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGQwYpzAwMrAwGDP4AYk+aC0AQMLgyQDAxMDKzMDVhCQ5prC4PCA4QEDQwqQywkmGRgYQQQA+uoGagAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAB4nB2LvQ6CQBgEb2+/H7wjYmGg0oICOoxEodPYanwM3/8VPN1qkpkNCL99wis8Qlh7a2Bu/YTLDXN3xHzDMA79FvvCyx3Lusxde/hH43DCUEx3bru2hNfLhLfVunFaMtKTMApBVk1tlussGxdvCIC1MiJKhIjvkmh6muaKpm6uUoQIzcvHVVOimSgZjZGIoECjVlk9WwhfuTELTwB4nGNgZGBgAGKt1CfX4/ltvjJwM6QARRgev+jgQqYZUhm8gSQHAxOIAwA0jAnPAAB4nGNgZGBgSGFggJD//zOkMjAyoAImAEiOAzAAAAAAAABkAAAAAAAAAGIAAHicY2BkYGBgYnBiANEgFgMDFxAyMPwH8xkADwkBXAAAeJxlkD1uwkAUhMdgSAJSghQpKbNVCiKZn5IDQE9Bl8KYtTGyvdZ6QaLLCXKEHCGniHKCHChj82hgLT9/M2/e7soABviFh3p5uG1qvVq4oTpxm/Qg7JOfhTvo40W4S38o3MMbpsJ9POKdO3j+HZ0BSuEW7vEh3Kb/KeyTv4Q7eMK3cJf+j3APK/wJ9/HqDdPIFLEp3FIn+yy0Z3n+rrStUlOoSTA+WwtdaBs6vVHro6oOydS5WMXW5GrOrs4yo0prdjpywda5cjYaxeIHkcmRIoJBgbipDktoJNgjQwh71b3UK6YtKvq1VpggwPgqtWCqaJIhlcaGyTWOrBUOPG1K1zGt+FrO5KS5zGreJCMr/u+6t6MT0Q+wbaZKzDDiE1/kg+YO+T89EV6oAAAAeJxjYGKAAEYG7ICJkYmRmYEp04CBAQADMgCoAA==) format('woff');font-weight: normal;font-style: normal;}

  .proceduresTable {
  width: 100%;
  font-family: 'Noah';
  margin: 0 0 2em 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

  .proceduresTable__tfoot .proceduresTable__td {
      padding-top: 2.5em;
    }

  .proceduresTable__link {
    color: #566E9E;
  }

  .proceduresTable__tr:nth-child(even) {
      background: rgba(78, 137, 255, .06);
    }

  .proceduresTable__tr:first-child td {
      padding-top: 1em;
    }

  .proceduresTable__td, .proceduresTable__th { 
    font-size: 18px;
    text-align: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: .5em 0;
    width: 50px;
    -webkit-transition: all ease .3s;
    transition: all ease .3s;
    opacity: .85;  
  }

  .proceduresTable__tr:hover .proceduresTable__td, .proceduresTable__tr:hover .proceduresTable__th {
      opacity: 1;
    }

  .proceduresTable__td:nth-child(1){
      min-width: 55%;
      opacity: 1;
      padding: .5em 1.5em;
      position: relative;
      text-align: left;
      color: #272729;
      font-size: 18px;
      font-weight: 500;
    }

  .proceduresTable__th:nth-child(1){
      min-width: 55%;
      opacity: 1;
      padding: .5em 1.5em;
      position: relative;
      text-align: left;
      color: #272729;
      font-size: 18px;
      font-weight: 500;
    }

  @media(max-width: 996px) {

  .proceduresTable__td:nth-child(1),.proceduresTable__th:nth-child(1){
        font-size: 16px
    }
      }

  .proceduresTable__th {
    padding-top: 2em;
    vertical-align: bottom;
    font-weight: 900;
  }

  .proceduresTable__td {
    color: #3470e8;
    font-size: 17px;
  }

  .proceduresTable__td:nth-child(1){
      color: #272729;
    }

  .proceduresTable__hint { 
    color: #78787A;
    font-weight: 400;
    font-size: .9em;
    line-height: 1.1em;

  }

  .proceduresTable__unlim:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: Dcd6f5;
      font-weight: normal;
      content: '\E000';
  }

  .proceduresTable__th {
    -webkit-transition: all ease .3s;
    transition: all ease .3s;
  }

  .proceduresTable__th:not(:nth-child(1)){
      opacity: .4;
    }

  .proceduresTable__tr:hover .proceduresTable__th:not(:nth-child(1)) {
        opacity: 1;
      }

  .proceduresTable__th:nth-child(1) {
      font-weight: 900;
    }

  .proceduresTable__th:nth-child(1):before {
        content: '';
        position: absolute;
        -webkit-transition: all ease .3s;
        transition: all ease .3s;
        display: inline-block;
        height: 8px;
        width: 8px;
        left: 0;
        bottom: 1em;
        -webkit-transform: rotate(40deg);
                transform: rotate(40deg);
        background: #D8AB5E;
        border-radius: 2px;
      }

  .proceduresTable__tr:hover .proceduresTable__th:nth-child(1):before {
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);
          border-radius: 50%;
        }

.proceduresTable__tr--header {
  background: transparent;
}

.proceduresBooking {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.proceduresBooking__button {
    margin-right: 40px;
  }

@media(max-width: 570px) {

.proceduresBooking__button {
      margin: 0;
      width: 100%
  }
    }

@media(max-width: 570px) {

.proceduresBooking__phone {
      display: none
  }
    }