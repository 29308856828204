.methodCard {
    height: 100px;
}
  @media(min-width: 571px) {.methodCard {
    height: 120px
}
  }
  .methodCard:after {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background: #272729;
    opacity: 0.45;
  }
  .methodCard:hover {
    text-shadow: 0 0 3px rgba(0,0,0, .3);
  }
  .methodCard:hover .card__cover {
      -webkit-transform:  scale(1.1);
              transform:  scale(1.1);
    }
  .methodCard .card__cover {
    -o-object-position: 0 40%;
       object-position: 0 40%;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;

  }
  .methodCard .card__title {
    font-weight: 500;
    line-height: 1.1em;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 20px;
  }
  @media(min-width: 571px) {
  .methodCard .card__title {
      font-size: 22px
  }
    }
  @media(max-width: 996px){
  .methodCard .card__content {
      padding: 1em 
  }
    }
  .methodCard__more {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    z-index: 3;
    padding: 1.5em;
    height: 100%;
    width: 100%;
  }