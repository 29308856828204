.youtubeCard {
  min-height: 160px;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
  .youtubeCard__title {
    z-index: 1;
    bottom: 18px;
    right: 22px;
    font-size: 13px;
    position: absolute;
  }
  @media(max-width: 996px) {
  .youtubeCard__title {
      display: none
  }
    }
  .youtubeCard__cover {
    -o-object-position: 50% 32%;
       object-position: 50% 32%;
  }
  .youtubeCard__hint {
    color: #ffffff;
    z-index: 2;
    position: relative;
    bottom: -70px;
    opacity: 0;
    transition: transform .3s ease,opacity .3s ease,margin-top .3s ease,-webkit-transform .3s ease;
  }
  .youtubeCard:before {
    content: '';
    background: #00000029;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .youtubeCard:after {
    transition: transform .3s ease,-webkit-transform .3s ease;
    -webkit-transition-delay: .3s;
            transition-delay: .3s;
    display: block;
    content: '';
    background: url('/images/youtube.svg') no-repeat;
    -o-object-fit: cover;
       object-fit: cover;
    width: 90px;
    height: 20px;
    top: 18px;
    right: 22px;
    position: absolute;
    z-index: 2;
  }
  .youtubeCard:hover .youtubeCard__hint {
      opacity: 1;
      bottom: -66px;
      margin-top: -24px;
      -webkit-transition-delay: 0s;
              transition-delay: 0s;
    }
  @media(max-width: 996px) {
  .youtubeCard:hover .youtubeCard__hint {
        bottom: -70px;
        opacity: 0
    }
      }
  .youtubeCard:hover:after {
      -webkit-transition-delay: 0s;
              transition-delay: 0s;
    }
  .youtubeCard:hover:before {
      background: #00000042;
    }
  .youtubeCard .card__cover {
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .youtubeCard__description {
    margin-top: 8px;
    font-family: Roboto;
    line-height: 21px;
  }