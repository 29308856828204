.saleWelcome {
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}
  .saleWelcome:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 330px;
    background: #566E9E;
  }
  .saleWelcome__button {
    padding: 4px 23px;
    margin-top: 10px;
  }
  @media(min-width: 1280px) {
  .saleWelcome__button {
      display: none
  }
    }
  .saleWelcome__container {
    width: 100%;
    margin-top: 100px;
    z-index: 1;
  }
  @media(max-width: 1279px) {
  .saleWelcome__container {
      margin-top: 90px
  }
    }
  @media(max-width: 769px) {
  .saleWelcome__container {
      margin-top: 60px
  }
    }
  .saleWelcome__container .sale__date--mobile {
      display: none;
      margin-bottom: 20px;
    }
  @media(min-width: 1280px) {
  .saleWelcome__container .sale__date--mobile {
        display: none
    }
      }
  .saleWelcome__container .sale__price_vcard--mobile {
      display: block;
      color: #ffffff;
      margin: 14px 0 10px;
    }
  .saleWelcome__container .sale__price_vcard--mobile .sale__oldPrice {
        font-size: 14px;
      }
  .saleWelcome__container .sale__price_vcard--mobile .sale__currentPrice {
        font-size: 28px;
      }
  @media(min-width: 1280px) {
  .saleWelcome__container .sale__price_vcard--mobile {
        display: none
    }
      }
  @media(max-width: 1279px) {
  .saleWelcome__container .heading.saleWelcome__heading {
        font-size: 30px
    }
      }
  @media(max-width: 570px) {
  .saleWelcome__container .heading.saleWelcome__heading {
        font-size: 28px
    }
      }