.methods__list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
  @media(min-width: 571px) {.methods__list {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr))
}   
  }
  @media(min-width: 769px) {.methods__list {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr))
}   
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {.methods__list {
    display: block;
    width: 100%
}
    .methods__list > a {
      width: calc(24.975% - 22.5px);
      margin-bottom: 30px;
    }
    .methods__list > a:nth-child(1n) {
      float: left;
      margin-right: 30px;
      clear: none;
    }
    .methods__list > a:last-child {
      margin-right: 0;
    }
    .methods__list > a:nth-child(4n) {
      margin-right: 0;
      float: right;
    }
    .methods__list > a:nth-child(4n + 1) {
      clear: both;
    }
  }