.treatment__slider {
    margin: -140px 0 30px 0;
    position: relative;
  }
  .treatment__title {
    margin: 40px 0 30px;
    color: #ffffff;
  }

@media(min-width: 997px) {
    .treatment__slider {
      margin-top: -200px;
    }
}