@font-face { font-family: D9c1e6; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAPMAAsAAAAABfwAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAOAAAAFYpKzXaY21hcAAAAXwAAABKAAABcOEoo6pnbHlmAAAByAAAAEcAAABUGEcEumhlYWQAAAIQAAAALQAAADYm+k0jaGhlYQAAAkAAAAAYAAAAJAAcABNobXR4AAACWAAAAAgAAAAIABAAAGxvY2EAAAJgAAAABgAAAAYAKgAAbWF4cAAAAmgAAAAeAAAAIAEOACduYW1lAAACiAAAASkAAAIWm5e+CnBvc3QAAAO0AAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGTgYJzAwMrAwMDFwA0kmaA0OxCzgLiszAxYQUCaawqDwwOGBwwMAkAuI5gE0UAAAJPmBGJ4nO2QsQ2AMBADz8pDgRiBKjWzULG/2CT5GNgils6ST189sAAlOZMA3YiRK63sC5t9UH0Twz+09nfucKOVmd19fMu/ekEdGiYJUQAAeJxjYGIAAW4GPgYOBikGBnMRQ3Z1U0NzcVERQ3FRdVFxZXV2ZXtGdlNzY05GIGBiAhEQBi8jGDCBCRZmZhYgC2QcAN1sBBwAeJxjYGRgYADi6nmC7fH8Nl8ZuBkEgCIMj190sCDTQHE+IMnBwATiAAAHqgg/AAAAeJxjYGRgYBBgYICT3AyMDKiACQAEqwAwAAAAAAAQAAAAAAAAACoAAHicY2BkYGBgYpAGYgYwi4GBCwgZGP6D+QwAC1EBNgAAeJxlkD1uwkAUhMdgSAJSghQpKbNVCiKZn5IDQE9Bl8KYtTGyvdZ6QaLLCXKEHCGniHKCHChj82hgLT9/M2/e7soABviFh3p5uG1qvVq4oTpxm/Qg7JOfhTvo40W4S38o3MMbpsJ9POKdO3j+HZ0BSuEW7vEh3Kb/KeyTv4Q7eMK3cJf+j3APK/wJ9/HqDdPIFLEp3FIn+yy0Z3n+rrStUlOoSTA+WwtdaBs6vVHro6oOydS5WMXW5GrOrs4yo0prdjpywda5cjYaxeIHkcmRIoJBgbipDktoJNgjQwh71b3UK6YtKvq1VpggwPgqtWCqaJIhlcaGyTWOrBUOPG1K1zGt+FrO5KS5zGreJCMr/u+6t6MT0Q+wbaZKzDDiE1/kg+YO+T89EV6oAAAAeJxjYGLAD5gYmRiZGZgyDRgYAAMPAKcA) format('woff');font-weight: normal;font-style: normal;}
  .mainAbout {
  overflow: hidden;
  margin: 50px 0 20px;
}
  @media(min-width: 1280px) {.mainAbout {
    margin: 80px 0 50px
}
  }
  .mainAbout__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  @media(max-width: 1279px) {
  .mainAbout__grid {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column
  }
    }
  .mainAbout__left {
    width: 50%;
  }
  @media(max-width: 1279px) {
  .mainAbout__left {
      width: 70%
  }
    }
  @media(max-width: 769px) {
  .mainAbout__left {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1;
      width: 100%
  }
    }
  .mainAbout__right {
    width: 44%;
    margin-left: 6%;
  }
  @media(max-width: 1279px) {
  .mainAbout__right {
      width: 100%
  }
    }
  @media(max-width: 769px) {
  .mainAbout__right {
      -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
              order: 0;
      margin-left: 0;
      width: 100%
  }
    }
  .mainAbout__footer {
    position: relative;
    top: -50px;
    margin: 0 0 0 15%;
  }
  @media(max-width: 570px) {
  .mainAbout__footer {
      margin: 0 auto
  }
    }
  .mainAbout__cover {
    max-width: 690px;
    height: 470px;
  }
  @media(max-width: 570px) {
  .mainAbout__cover {
      height: 280px
  }
    }
  .mainAbout__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-top: 20px;
  }
  .mainAbout__item {
    padding: 3.5px 12px;
    margin-right: 12px;
    font-size: 14px;
    font-family: 'Roboto';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
    background: rgba(81, 127, 18, 0.12);
    position: relative;
    color: #517F12;
  }
  .mainAbout__item:before {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: D9c1e6;
      font-weight: normal;
      content: '\E000';
      font-size: 16px;
      height: 16px;
      margin-right: 2px;
    }
  .mainAbout__item:first-child {
      color: #D8AB5E;
      background: rgba(216, 171, 94, 0.12);
    }
  @media(max-width: 570px) {
  .mainAbout__item {
      margin-bottom: 10px
  }
    }
  .mainAbout__description {
    font-size: 16px;
    line-height: 28px;
    max-width: 420px;
  }
  @media(max-width: 769px) {
  .mainAbout__description {
      margin: 10px 0 20px
  }
    }
  .mainAbout__img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 8px;
    position: relative;
  }
  @media(max-width: 570px) {
  .mainAbout__img {
      width: calc(100% + 30px);
      left: -15px;
      overflow: initial;
      border-radius: 0
  }
    }
  .mainAbout__map {
    width: 520px;
    height: 170px;
    margin: 32px 0;
  }
  @media(max-width: 570px) {
  .mainAbout__map {
      display: none
  }
    }
  .mainAbout__map img {
      width: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }