@font-face { font-family: yc23cd; src:url(data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAAPQAAsAAAAABegAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADsAAABUIIslek9TLzIAAAFEAAAAPQAAAFYpSTXzY21hcAAAAYQAAABKAAABcOEoo6pnbHlmAAAB0AAAAD0AAABA1Zbm0WhlYWQAAAIQAAAALQAAADYnCU0yaGhlYQAAAkAAAAAbAAAAJAA1ABtobXR4AAACXAAAAAgAAAAIABkAAGxvY2EAAAJkAAAABgAAAAYAIAAAbWF4cAAAAmwAAAAeAAAAIAENAB5uYW1lAAACjAAAASkAAAIWm5e+CnBvc3QAAAO4AAAAFwAAACxqNwEEeJxjYGRgYOBiMGCwY2BycfMJYeDLSSzJY5BiYGGAAJA8MpsxJzM9kYEDxgPKsYBpDiBmg4gCACY7BUgAeJxjYGTgZZzAwMrAwMDPIAgkmaE0DwMjAxsDAxMDKzMDVhCQ5prC4PCA4QEDgyQDSKEkWJgRRAAAnrMEmQAAAHic7ZCxDYAwEAPPykOBGIEqNbNQsb/YJPkY2CKWzpJPXz2wACU5kwDdiJErrewLm31QfRPDP7T2d+5wo5WZ3X18y796QR0aJglRAAB4nGNgZAABKQYRBkEGBnMRfkY2JX1GNRMzI1FxcxMjMUlGLkZGEGbi4mRiFAIzuRiZGBk5ORlBWgF3iwMjAAAAeJxjYGRgYADhMt/V8fw2Xxm4GSSBPIbHLzpYkGkGKQYRIMnBwATiAAD9FQgaAAAAeJxjYGRgYJBkYICQ//8zSDEwMqACJgAylQJPAAAAAAAAGQAAAAAAAAAgAAB4nGNgZGBgYGIQYgDRIBYDAxcQMjD8B/MZAApZASwAAHicZZA9bsJAFITHYEgCUoIUKSmzVQoimZ+SA0BPQZfCmLUxsr3WekGiywlyhBwhp4hyghwoY/NoYC0/fzNv3u7KAAb4hYd6ebhtar1auKE6cZv0IOyTn4U76ONFuEt/KNzDG6bCfTzinTt4/h2dAUrhFu7xIdym/ynsk7+EO3jCt3CX/o9wDyv8Cffx6g3TyBSxKdxSJ/sstGd5/q60rVJTqEkwPlsLXWgbOr1R66OqDsnUuVjF1uRqzq7OMqNKa3Y6csHWuXI2GsXiB5HJkSKCQYG4qQ5LaCTYI0MIe9W91CumLSr6tVaYIMD4KrVgqmiSIZXGhsk1jqwVDjxtStcxrfhazuSkucxq3iQjK/7vurejE9EPsG2mSsww4hNf5IPmDvk/PRFeqAAAAHicY2BiwA+YGJkYmRmYMg0YGAADDwCnAA==) format('woff');font-weight: normal;font-style: normal;}
    .questions__title {
    font-size: 2em;
    margin-bottom: 10px;
  }
    @media(min-width: 769px) {.questions__title {
      margin-bottom: 25px
  }
    }
  .questions__list {
    margin-top: 0;
  }
  .questions__header {
    font-size: 20px;
    color: #272729;
    display: block;
    line-height: 1.2;
    opacity: .9;
    font-weight: 900;
    max-width: 700px;
    cursor: pointer;
    font-family: 'Noah';
  }
  @media(max-width: 769px) {
  .questions__header {
      font-size: 18px;
      padding-right: 20px
  }
    }
  .questions__content {
    font-size: 16px;
    display: none;
    margin-top: 8px;
    font-weight: 300;
    padding: 15px;
  }
  .questions__content p {
      margin: 14px 0;
    }
  @media(max-width: 769px) {
  .questions__content {
      font-size: 14px;
      padding: 0
  }
    }
  .questions__items {
    width: 100%;
  }
  .questions__item {
    position: relative;
    padding: 14px 0;
    margin-bottom: 0px;
    cursor: pointer;
  }
  @media(min-width: 769px) {
  .questions__item {
      padding: 6px 0;
      margin-bottom: 20px
  }
    }
  .questions__item:after {
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: yc23cd;
      font-weight: normal;
      content: '\E000';
      font-size: 14px;
      position: absolute;
      right: 0;
      top: 8px;
      color: #D8AB5E;
      opacity: .8;
    }
  @media(max-width: 769px) {
  .questions__item:after {
        right: 0;
        top: 16px
    }
      }
  .questions__item.questions__item--opened .questions__content {
        display: block;
      }
  .questions__item.questions__item--opened:after {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
      }