.roomDetails {
  margin: 1em 0;
}
  .roomDetails__grid {
    border-top: 1px solid #DDDFE0;
    padding-top: 24px;
    width: 100%;
    display: grid;
    grid-template-columns:  1fr;
    grid-gap: 20px;
    grid-auto-flow: dense;
  }
  @media(min-width: 769px) {
  .roomDetails__grid {
      grid-template-columns: 35% 1fr
  }
    }
  @media(min-width: 1280px) {
  .roomDetails__grid {
      grid-template-columns: 30% 1fr
  }
    }
