.reviewsCards__section {
    margin: 40px 0 80px;
  }
  .reviewsCards__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
  }
  @media(max-width: 996px) {
  .reviewsCards__grid {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      margin: 0
  }
    }
  .reviewsCards__grid .swiper-wrapper {
      margin-bottom: 25px;
    }

.searchReviews {
  margin: 40px 0 60px;
}

.searchReviews__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 20px 0 0;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
  }

.searchReviews__description {
    max-width: 590px;
    margin-top: 5px;
    font-size: 16px;
    color: #272729;
    font-weight: 300;
  }

.searchReviews__column {
    width: 48.5%;
    background:  #F5F5F7;
    padding: 24px;
    margin-right: 3%;
    position: relative;
    border-radius: 8px;
  }

.searchReviews__column:last-child {
      margin-right: 0;
    }

@media(max-width: 996px) {

.searchReviews__column {
      width: calc(100% - 24px);
      margin: 20px 0 0 0;
      padding: 12px
  }
    }