.voucherDetails {
  margin: 2em 0;
}
  .voucherDetails__content h4:first-child {
    font-size: 15px;
    color: #78787A;
    font-weight: 500;
    font-family: 'Noah';
    margin: 14px 0;
  }
  .voucherDetails li {
    line-height: 1.2em;
    margin: .7em 0;
  }
  .voucherDetails__description {
    font-size: 14px;
    font-weight: 300;
  }