@media(min-width: 997px) {.roomReviewsAndSiblings__grid {
      width: 100%;
      display: grid;
      // grid-template-columns: calc(100% - 24px) 1fr;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      grid-gap: 24px
  }
    }
    @media(max-width: 996px) {.roomReviewsAndSiblings__grid {
      width: 100%;
      display: block
  }
    }
