.reviews__ratingStars {
    margin-bottom: 7px;
  }
    .reviews__ratingStars img {
      margin-right: 4px;
    }
    .reviews__ratingStars--absolute {
      position: absolute;
      top: 16px;
      left: 24px;
      z-index: 1;
    }
    .reviews__ratingStars--absolute img {
        width: 16px;
      }
    .reviews__ratingStars--bigStar {
      margin-top: 5px;
    }
    .reviews__ratingStars--bigStar img {
        width: 17px;
      }