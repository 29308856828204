.rest__article {
  margin-top: 1em;
  white-space: pre-line;
}
.service__column {
    width: 68%;
  }
.service__column img {
      width: 100%;
    }
@media(max-width: 996px) {
.service__column img {
        width: 100%
    }
      }
@media(max-width: 1440px) {
.service__column {
      width: 100%
  }
    }