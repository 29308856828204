.roomComfortable__list {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 1em;
       -moz-column-gap: 1em;
            column-gap: 1em;
  }
    .roomComfortable__list li {
      width: calc(100% - 24px);
    }
    @media(min-width: 571px){.roomComfortable__list {
      -webkit-column-count: 3;
         -moz-column-count: 3;
              column-count: 3;
      -webkit-column-gap: 2em;
         -moz-column-gap: 2em;
              column-gap: 2em
  }
    }
    @media(min-width: 769px) {.roomComfortable__list {
      -webkit-column-count: 1;
         -moz-column-count: 1;
              column-count: 1;
      -webkit-column-gap: 0;
         -moz-column-gap: 0;
              column-gap: 0
  }
    }