.methods__description {
  margin-bottom: 40px;
}

.method__content {
    margin: 40px 0 80px;
  }

.method__grid {
    display: grid;
    width: 100%;
    grid-gap: 2em;
    grid-template-columns: 1fr;
  }

@media(min-width: 997px) {

.method__grid {
      grid-template-columns: 70% 1fr
  }
    }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

.method__grid {
      display: block;
      width: 100%
  }
      .method__grid > a {
        width: calc(16.65% - 25px);
        margin-bottom: 30px;
      }
      .method__grid > a:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none;
      }
      .method__grid > a:last-child {
        margin-right: 0;
      }
      .method__grid > a:nth-child(6n) {
        margin-right: 0;
        float: right;
      }
      .method__grid > a:nth-child(6n + 1) {
        clear: both;
      }
    }