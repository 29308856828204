.label {
  font-family: 'Noah';
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #ffffff;
  border-radius: 5px 5px 0 5px;
  z-index: 1;
  cursor: pointer;
}

  /**Margined**/

  .label.label--margined_right {
    margin-right: 20px;
  }

  .label.label--margined_left4 {
    margin-left: 40px;
  }

  /**Color**/

  .label.label--color_blue {
    color: #2F7DE1;
  }

  .label.label--color_pink {
    color: #ED4D74;
  }

  /**Size**/

  .label.label--size_medium {
    font-weight: 500;
    line-height: .9;
    padding: 11px;
    font-size: 17px;
  }

  .label.label--size_big {
    font-weight: 600;
    line-height: 26px;
    padding: 17px 14px;
    font-size: 24px;
  }

  /**Position**/

  .label.label--position_rt {
    position: absolute;
    right: 0;
    top: 0;
  }

