.restCard {
  height: 140px;
}
  .restCard:hover .card__cover {
   -webkit-transform: scale(1.15);
           transform: scale(1.15);
  }
  .restCard .card__cover {
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .restCard:after {
    background: #272729;
    opacity: 0.3;
  }
  .restCard:hover:after {
      background: #272729;
    }