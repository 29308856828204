.booking__description {
  margin: 15px 0 30px;
  max-width: 700px;
  font-size: 15px;
}
  .booking__description > p {
    margin-bottom: 14px;
  }

.booking__p {
    -webkit-box-shadow: 0 5px 40px rgba(72, 93, 200, 0.2);
            box-shadow: 0 5px 40px rgba(72, 93, 200, 0.2);
    display: block;
    padding: 15px;
    border-radius: 3px;
  }

.booking__link {
    display: block;
    margin-top: -14px;
  }